import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register (
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

var rendimiento = [0, 56, 20, 36, 80, 40, 30, -20, 25, 30, 12, 60];
var meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']

var midata = {
    labels: meses,
    datasets: [//Cada una de las lineas del grafico
        {
            label: 'Beneficios',
            data: rendimiento,
            tension: 0.5,
            fill: true,
            borderColor: 'rgb(255, 92, 132)',
            backgroundColor: 'rgba(255, 199, 132, 0.5)',
            pointRadius: 5,
            pointBorderColor: 'rgba(255, 199, 132)',
            pointBackgroundColor: 'rgba(255, 199, 132)'
        }

    ]

}

var misoptions = {
    scales: {
        y: {
            min : 0
        },
        x: {
            ticks: {color : 'blue'}
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
}

export default function LinesChart2 () {
    return <Line data={midata} options={misoptions} />
}