import React from "react";
import BannerBoletines from "../pageComponents/BannerBoletines";
import BodyBoletines from "../pageComponents/BodyBoletines"; 

const Boletines = () => {
    return (
        <>
            <BannerBoletines />
            <BodyBoletines />
        </>
    );
  };
  
export default Boletines;