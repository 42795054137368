import React, { useState } from "react";
import './BodyNormatividad.css';

const BodyNormatividad = () => {
    const[toggleState, setToggleState] = useState(1);

    const toggleEntity = (index) => {
        setToggleState(index);
    }

    return(
        <div className="body-normatividad">
            <div className="body-normatividad-text">
                <h2>Normatividad</h2>
                <br/>
            </div>
            <br/>
            <div className="body-normatividad-panel">
                <div className="normatividad-cards">

                    <div className = {toggleState === 0 ? "normmatividad-card card-intern active" : "normmatividad-card card-intern"} 
                         onClick={() => toggleEntity(0)}>
                        <div className="normatividad-card-image">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/A_large_blank_world_map_with_oceans_marked_in_blue.PNG/2560px-A_large_blank_world_map_with_oceans_marked_in_blue.PNG" alt="bandera colombia" />
                        </div>
                        <h4>INTERNACIONAL</h4>
                    </div>

                    <div className = {toggleState === 1 ? "normmatividad-card card-nacional active" : "normmatividad-card card-nacional"} 
                         onClick={() => toggleEntity(1)}>
                        <div className="normatividad-card-image">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Colombia_locator_map.svg" alt="escudo-risaralda" />
                        </div>
                        <h4>NACIONAL</h4>
                    </div>

                    <div className = {toggleState === 2 ? "normmatividad-card card-municipios active" : "normmatividad-card card-municipios"} 
                         onClick={() => toggleEntity(2)}>
                        <div className="normatividad-card-image">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Colombia_Risaralda_location_map_2.svg/952px-Colombia_Risaralda_location_map_2.svg.png" alt="bandera colombia" />
                        </div>
                        <h4>DEPARTAMENTAL</h4>
                    </div>

                </div>

                <div className="normatividad-docs">
                    <div className="normatividad-laws-title">
                        <h3>Archivos</h3>
                    </div>
                    <div className = {toggleState === 0 ? "normatividad-laws-internacional active" : "normatividad-laws-internacional"}>
                        <h4>Normas internacionales</h4>
                    </div>
                    <div className = {toggleState === 1 ? "normatividad-laws-nacional active" : "normatividad-laws-nacional"}>
                        <h4>Normas Nacionales</h4>
                        <div className="laws-nacional-year">
                            <div className="laws-subfold-title">
                                <h4>2022</h4>
                                <h4>Descargar</h4>
                            </div>
                            <div className="laws-subfold-content">
                                <div className="laws-subfold-item">
                                    <h5>T-128-22 parteras.rtf</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1hGos1K8Dez946OZQVbGK__R_nSkcFDaY/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>SUO48-22 violencia obstetrica.rtf</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/18XHOkzmbgR9Xu2jQ93aejZhHaG_XhK6q/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Plan de aceleración para la reducción de la
                                        mortalidad materna</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1qyybpZV9i65pS5qAb1D4c5pXEWPyNPgf/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Resolución No 411480 pdf-EDUCACIONSUPERIOR</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1sU-RGOHecsuxyE4_tGrsOM1bmQWZQjfk/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Bases del plan nacional de desarrollo-2022-2026</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1p7MEodRb2eWKQJ1BI74AQ_l5mFeEDiJo/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Documento Compes 4080</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1wR3BUueC2qDMMlb3Lmd2bOLFEK3i9-mf/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="laws-nacional-year">
                            <div className="laws-subfold-title">
                                <h4>2023</h4>
                                <h4>Descargar</h4>
                            </div>
                            <div className="laws-subfold-content">
                                <div className="laws-subfold-item">
                                    <h5>T-198-23 sentencia violencia obstetrica</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1B7t0rX2Gad_-Vc5NXJ1x9yOnJgxViDoz/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Resolución No. 051 de 2023</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/17VX4r9_P94SzEIwUn2sNAO3vYevprmAq/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Protocolo 1 Directiva 1 2023 Proteccion formas de violencia contra mujeres</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1QDI3fHZPqAN6KSXHnFv95weDD5QH81Fc/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>LEY 2294 DEL 19 DE MAYO DE 2023</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1oIOrBzOU80IL8NZo1pfV5SZRkeMoZkRs/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>F- Acta de Reunion</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1w3OFfEI0AqLaC--XJvxbQKgfUVJgQyom/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Directiva 01 del 8-mar-2023 Protocolo formas de violencia contra mujeres</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1Ba8Sql9GJsuZEJJMtQj9o5ADD2b76nfb/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>CTO1 Categorización(documento Excel)</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://docs.google.com/spreadsheets/d/10fvwhhw7vDr6wP0in9ktXYbMvg7Vd93a/edit#gid=423789070"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className = {toggleState === 2 ? "normatividad-laws-departamental active" : "normatividad-laws-departamental"}>
                        
                        <h4>Normas departamentales</h4>
                        <div className="laws-departamental-ordenanzas">
                            <div className="laws-subfold-title">
                                <h4>Ordenanzas</h4>
                                <h4>Descargar</h4>
                            </div>
                            <div className="laws-subfold-content">
                                <div className="laws-subfold-item">
                                    <h5>ORD-005-2015 MUJER</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1e8BWLWkXFQI2QALo7fSaLepGSH9R-bkG/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>008- POLITICA PUBLICA EQUIDAD GENERO.pdf</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1WreUVaV7x-0ZmVefoj7RJWvd5RCktvlt/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="laws-departamental-decretyres">
                            <div className="laws-subfold-title">
                                <h4>Decretos y Resoluciones</h4>
                                <h4>Descargar</h4>
                            </div>
                            <div className="laws-subfold-content">
                                <div className="laws-subfold-item">
                                    <h5>DECRETO 0895 - OAG</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1xATSrC4edc3xYqR59GCBmXB9XH87SEjM/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Decreto 837 del 26 de septiembre de 2022</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1FjYXcZMbU9vKV3gFbXSSA7Ym7tNaNBwj/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>DECRETO 0154 DE 2023</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/11aC8Ag5PCrl37LGnulIAbRTm3m1E_4n5/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Copia de Resolución 1721 de 2021</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1UP1skHPokfS6tozd1teMsWnxqIKHtkiy/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                                <div className="laws-subfold-item">
                                    <h5>Circular Externa No. 47 de 2022</h5>
                                    <div className="laws-subfold-icon">
                                        <a target="blank" href="https://drive.google.com/file/d/1uYQeiDzUYS8-JZMdUeCttFvqRxp1AhLX/view"><i class="fa-solid fa-download"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

/*class BodyNormatividad extends Component {
    render(){
        return(
            <div className="body-normatividad">
                <div className="body-normatividad-text">
                    <h2>Normatividad</h2>
                    <br/>
                </div>
                <br/>
                <div className="body-normatividad-panel">
                    <div className="normatividad-cards">

                        <div className="normmatividad-card card-intern">
                            <div className="normatividad-card-image">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/A_large_blank_world_map_with_oceans_marked_in_blue.PNG/2560px-A_large_blank_world_map_with_oceans_marked_in_blue.PNG" alt="bandera colombia" />
                            </div>
                            <h4>INTERNACIONAL</h4>
                            <a href="/"><p>ver documentos</p></a>
                        </div>

                        <div className="normmatividad-card card-nacional">
                            <div className="normatividad-card-image">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Colombia_locator_map.svg" alt="escudo-risaralda" />
                            </div>
                            <h4>NACIONAL</h4>
                            <a href="/"><p>ver documentos</p></a>
                        </div>

                        <div className="normmatividad-card card-municipios">
                            <div className="normatividad-card-image">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Colombia_Risaralda_location_map_2.svg/952px-Colombia_Risaralda_location_map_2.svg.png" alt="bandera colombia" />
                            </div>
                            <h4>DEPARTAMENTAL</h4>
                            <a href="/"><p>ver documentos</p></a>
                        </div>

                    </div>

                    <div className="normatividad-docs">
                        <div className="normatividad-laws-title">
                            <h3>Archivos</h3>
                        </div>
                        <div className="normatividad-laws-internacional">
                            <h4>Ley internacional</h4>
                        </div>
                        <div className="normatividad-laws-nacional">
                            <h4>Ley nacional</h4>
                            <div className="laws-nacional-year">
                                <div className="laws-subfold-title">
                                    <h4>2022</h4>
                                    <h4>Descargar</h4>
                                </div>
                                <div className="laws-subfold-content">
                                    <div className="laws-subfold-item">
                                        <h5>T-128-22 parteras.rtf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>SUO48-22 violencia obstetrica.rtf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>plan-reduccion-aceleracion-msps.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>articles-411480_pdf-EDUCACIONSUPERIOR(1).pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>230206-Plan-Nacional-de-Desarrollo-2022-2026.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>4080.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="laws-nacional-year">
                                <div className="laws-subfold-title">
                                    <h4>2023</h4>
                                    <h4>Descargar</h4>
                                </div>
                                <div className="laws-subfold-content">
                                    <div className="laws-subfold-item">
                                        <h5>T-198-23 sentencia violencia obstetrica.rtf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>Resolución No. 051 de 2023.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>Protocolo 1 Directiva 1 2023 Proteccion formas de violencia contra mujeres.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>LEY 2294 DEL 19 DE MAYO DE 2023.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>F- Acta de Reunion.docx</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>Directiva 01 del 8-mar-2023 Protocolo formas de violencia contra mujeres.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>CTO1 Categorización.xlsx</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="normatividad-laws-departamental">
                            
                            <h4>Ley departamental</h4>
                            <div className="laws-departamental-ordenanzas">
                                <div className="laws-subfold-title">
                                    <h4>Ordenanzas</h4>
                                    <h4>Descargar</h4>
                                </div>
                                <div className="laws-subfold-content">
                                    <div className="laws-subfold-item">
                                        <h5>ORD-005-2015 MUJER.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>008- POLITICA PUBLICA EQUIDAD GENERO.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="laws-departamental-decretyres">
                                <div className="laws-subfold-title">
                                    <h4>Decretos y Resoluciones</h4>
                                    <h4>Descargar</h4>
                                </div>
                                <div className="laws-subfold-content">
                                    <div className="laws-subfold-item">
                                        <h5>DECRETO0895-OAG.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>Decreto 837 del 26 de septiembre de 2022.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>DECRETO 0154 DE 2023.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>Copia de Resolución 1721 de 2021.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="laws-subfold-item">
                                        <h5>Circular Externa No. 47 de 2022.pdf</h5>
                                        <div className="laws-subfold-icon">
                                            <a href="/"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
*/
export default BodyNormatividad;