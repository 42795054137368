import React, { useState } from "react";
import './BodyAcademia.css';

const BodyAcademia = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleEntity = (index) => {
            setToggleState(index)
        }
    
    return(
        <div className="body-academia">
            <div className="body-academia-title">
                    <h2>Academia</h2>
                    <br/>
                    <h3>Estudios e investigaciones sobre asuntos de género en el departamento de Risaralda</h3>
                    <br />
            </div>
            <div className="body-academia-table">
                <div className="main-panel">
                    <div className="left-panel">

                        <div className="panel-title">
                            <h3 className="left-panel-title">
                                Clasificación
                            </h3>
                        </div>

                        <div className = { toggleState === 0 ? "left-panel-autonomia selected" : "left-panel-autonomia"}
                            onClick={() => toggleEntity(0)}>
                            <h4>Autonomía toma de decisiones</h4>
                        </div>

                        <div className = { toggleState === 1 ? "left-panel-autonomia selected" : "left-panel-autonomia"}
                            onClick={() => toggleEntity(1)}>
                            <h4>Autonomía física</h4>
                        </div>

                        <div className = { toggleState === 2 ? "left-panel-autonomia selected" : "left-panel-autonomia"}
                            onClick={() => toggleEntity(2)}>
                            <h4>Autonomía económica</h4>
                        </div>    

                    </div>

                    <div className="files-panel">

                        <div className="files-bar">
                            <div className="files-title">
                                <h3>Título</h3>
                            </div>
                            <div className="files-title institucion">
                                <h3>Institución</h3>
                            </div>
                            <div className="files-title">
                                <h3>Descargar</h3>
                            </div>
                        </div>

                        <div className = { toggleState === 0 ? 'publicaciones-items-autonomia active' : 'publicaciones-items-autonomia'}>
                        
                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>REPRESENTACIONES SOCIALES DE LAS PERSONAS DIVERSAS POR
                                    ORIENTACIÓN SEXUAL E IDENTIDAD DE GÉNERO EN EL DIARIO DEL OTÚN Y
                                    EL DIARIO, DE LA CIUDAD DE PEREIRA, DURANTE EL PERIODO 2012-2018
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1XQ4wN_y3o8rNgJpo1lKa085ku9i-PIab/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Realidades de las mujeres migrantes venezolanas en la ciudad de Pereira: 
                                    una mirada a la institucionalidad
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1q_jSaj2J6qPBKVPYmuPV7LF2DBvQZlBg/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>PARTICIPACIÓN POLÍTICA DE LA MUJER EN EL MUNICIPIO DE PEREIRA
                                    PERÍODO 1991-2003
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1jJWRloKY6p2JM9neDxenCIlvJ9E5DsWv/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>MÁS ALLÁ DE UN PINTALABIOS:
                                    REPRESENTACIONES SOCIALES DE LAS MUJERES DE PEREIRA
                                    DURANTE LA DÉCADA 1975-1985
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1EYdEEBUln29rQcNVYpiUCEthI3mwUj48/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>LA LITERATURA EN EL AULA COMO MEDIO PARA LA REIVINDICACIÓN
                                    FEMENINA: UNA PROPUESTA DIDÁCTICA PARA EL FORTALECIMIENTO DE LA
                                    COMPRENSIÓN LECTORA Y COMPETENCIA CRÍTICO ARGUMENTATIVA
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1CISNJV5FUFyf9OXmdmHESQiIevmo75Lo/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>LA EXPERIENCIA SENSIBLE EN LA CONFIGURACIÓN DE LA MEMORIA DE LAS
                                    MUJERES DEL CORREGIMIENTO DE SANTA ANA
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1yTyuPPN7_vp-fpuI8PBdWyhJYBMhpLC5/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Identidades y lenguajes en la literatura
                                    escrita por mujeres
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1sC1_WRo52VvJ4Mr8zrFpyWlX1avp6kRA/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Género en la infancia: un mundo por explorar
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1WMq8Y0a924j8cdUhw_bwyOzHym3vUseV/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>ESTUDIO SOBRE VALORES Y ANTIVALORES ORGANIZACIONALES APLICADO A
                                    ESTUDIANTES DE INGENIERÍA INDUSTRIAL DE LA UNIVERSIDAD TECNOLÓGICA DE PEREIRA
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/19AYKep3rT8IR0xisi9KHtL4o-QH1zVnf/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>En busca del arco iris Diversidad sexual e identidad de
                                    género para una escuela libre de discriminación
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1KQn2WgXAQc_xbNzImgfjYmUVm-_zLiWJ/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Construcción de la identidad y roles de género en las mujeres embera chamí del resguardo
                                        Totumal en Belalcázar, Caldas: 1990-2020
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1yoFg8vgAiy2TaV8gCGaCEcYZM8PgJrvc/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>CONSTRUCCIÓN DE IDENTIDAD: TE REPRESENTÉ COMO MUJER EN UN VIDEOCLIP MUSICAL
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/13aoLNwQs8yC-plHyX3CoPbTJ3CpT25ez/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Categorías gramaticales que construyen el concepto de feminicidio en El Diario, 
                                        periódico regional de la ciudad de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1caQNnhDTiIZ_ej9o19VFePI8A4vHJ25A/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Análisis de contenido comparativo de los estereotipos de género femenino en las
                                        series televisivas colombianas “El último matrimonio feliz” (2009) y 
                                        “La ley del corazón 2” (2019) del canal RCN
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1ikFVJnLPqqdTzidFxGQb7d_PJxaCGhEc/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>

                        <div className = { toggleState === 1 ? 'publicaciones-items-autonomia active' : 'publicaciones-items-autonomia'}>
                        
                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Formación sobre prevención de violencia de género a las 29 organizaciones de mujeres
                                        líderes del consejo consultivo del Departamento de Risaralda
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Cooperativa de Colombia
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1qep3dODHcBdBB1L3lfg-pX9Q8lEMe30Y/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>FORMAS DE VIOLENCIA OBSTÉTRICA EN GESTANTES ATENDIDAS DURANTE
                                        TRABAJO DE PARTO, PARTO Y POSPARTO EN DOS INSTITUCIONES DE SALUD EN RISARALDA 2022
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Fundación Universitaria del Área Andina
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1e70tf9Xjd-XSDdp4kcVfzT6smvC55ssd/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>IMPLEMENTACIÓN Y ÁNALISIS DE ESTRATEGIAS PARA EL
                                        MEJORAMIENTO DE LOS PROCESOS DE LA RUTA DE ATENCIÓN
                                        DE VÍCTIMAS DE VIOLENCIA EN LA UNIVERSIDAD
                                        TECNOLÓGICA DE PEREIRA
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1sjtaDVLLR-jsv2p8gkK2mQzPjpfqJwPw/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>LA EFICACIA DEL INCIDENTE DE REPARACIÓN INTEGRAL FRENTE A LAS
                                        VÍCTIMAS DEL TIPO PENAL DE TRATA DE PERSONAS EN COLOMBIA
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Libre de Colombia
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1Po2K8xypeDdVEkxPgRbmtgaBCYIrfdjy/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>SIGNIFICACIÓN IDENTITARIA Y FEMINISMOS EN UNA MUJER JOVEN DE
                                        PEREIRA VÍCTIMA DE VIOLENCIA DE GÉNERO
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Católica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1Nf7_WvtClk6blLrHz3jK-a-8Rnx0D5CX/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Reflexiones sobre ser mujeres,
                                        activistas e investigar sobre
                                        violencias de género
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Católica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1B9pEX5WYhEKjK6e_SjBg3kCF14Od5afb/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>DIFERENCIAS DE ACCESO EN LAS MEDIDAS DE ASISTENCIA Y ATENCIÓN EN 
                                        SALUD Y EDUCACIÓN FRENTE A LA ATENCION INTEGRAL A LAS VÍCTIMAS 
                                        DEL CONFLICTO ARMADO EN COLOMBIA
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Libre de Colombia
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1hFOa8rOfJtRsk-kK1FOZgK84L8Oo7oqC/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Violencia bidireccional en el noviazgo entre jóvenes offline y online
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Católica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1Ai3wv0l0bRiJjzMxkBgOBh8VKIS8Te8T/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Imagen corporal en la mujer víctima de violencia psicológica de género en las 
                                        relaciones de pareja: un estudio de caso
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Católica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1yOkqWSC_3lEePex5UGoc8A4c5-c7SCTN/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>PERCEPCIÓN DE LOS TOMADORES DE DECISIÓN EN SALUD, FRENTE A LA
                                        ATENCIÓN INTEGRAL DE LA POBLACIÓN VÍCTIMA DEL CONFLICTO ARMADO EN RISARALDA 2019
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1mUpTD3FtVXtDAbqJGClJGO_BfRe-hOzP/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Violencia de noviazgo online normalización de conductas violentas por medio de 
                                        redes sociales, una vinculación a la construcción de amor en tiempos virtuales en 
                                        jóvenes universitarios de la ciudad de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Católica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1gO2-pueghZ3c2sI_Vc2hEDLzyfeKGE3H/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Violencia de Género en Jóvenes: Una Mirada Crítica de Trabajo Social en el Contexto
                                        Colombiano y Pereirano
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Libre de Colombia
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/16Htvjw9WZzEyEz9nTBl3hsyspGxbd0aG/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Afrontamiento y regulación emocional en mujeres para la mejora de su bienestar
                                        individual y comunitario dentro del contexto del conflicto armado en Colombia
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Del País Vasco
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1x4AF7Ph5qAwPiMoruYi6HP2A5bhVqHeK/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>VIOLENCIA CONTRA LA MUJER, EN EL MUNICIPIO DE MARSELLA RISARALDA 2018
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Fundación Universitaria del Área Andina
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1Ky6qeCzoDCaSwY2fb8cvQVjKjHHl0e0d/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>TRATA DE PERSONAS EN COLOMBIA: INDICADORES PARA UNA ESTRATEGIA
                                        DE MONITOREO DESDE LAS REGIONES
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Libre Seccional Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1o2qcQ60mwzNQ_wuMixlRJ57URy7vcwlU/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Violencias en los departamentos de Cauca, Quindío, Risaralda, y Valle del Cauca
                                        en el año 2018
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Fundación Universitaria del Área Andina
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1Gqva1cFjYVtUDvjPaEIqGn7W0nYH_qQn/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>Violencia conyugal y dependencia afectiva: un estudio cualitativo
                                        en población masculina
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Cooperativa de Colombia
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1DvntZP-quHh4FCvsfq443oqBUrI2x2u5/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>FORTALECIMIENTO INTEGRAL DE LA ORGANIZACIÓN JUVENIL PARA
                                        INCIDIR Y PREVENIR EL FENÓMENO DE LA TRATA DE PERSONAS Y
                                        OTRAS VIOLENCIAS EN CONTEXTOS EDUCATIVOS DE LA COMUNA CUBA
                                        DE LA CIUDAD DE PEREIRA
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Libre Seccional Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1EVjNoAgg6QKooxJS5nrRbBeqeMA3DZdM/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>

                        <div className = { toggleState === 2 ? 'publicaciones-items-autonomia active' : 'publicaciones-items-autonomia'}>

                            <div className="publicaciones-item-institucion">
                                <div className="publicaciones-table-document">
                                    <h4>La incorporación laboral de mujeres migrantes venezolanas en la ciudad de
                                        Pereira. Proceso y tipologías.
                                    </h4>
                                </div>
                                <div className="publicaciones-table-document">
                                    <h4>Universidad Tecnológica de Pereira
                                    </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a target="blank" href="https://drive.google.com/file/d/1rJjPWaqukwJ0SdHxXo7hjb5kDJWzOQ04/view"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

/*class BodyAcademia extends Component {
    render(){
        return(
            <div className="body-academia">
                <div className="body-academia-title">
                    <h2>Academia</h2>
                    <br/>
                    <h3>Estudios e investigaciones sobre asuntos de genero</h3>
                </div>
                <br/>
                <br/>
                <div className="body-academia-table">

                    <div className="academia-table-item">
                        <div className="academia-table-title table-title">
                            <h3>AUTONOMÍA</h3>
                        </div>
                        <div className="academia-table-document table-header">
                            <h3>DOCUMENTOS</h3>
                        </div>
                    </div>
                
                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía toma de decisiones</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Equidad de género en educación</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Violencia de género 2022</h4>
                            </a>
                        </div>
                    </div>
                
                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Ruta de atención 2022</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Feminismo mujer víctima de VBG 2021</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Diferencias de acceso en las medidas de asistencia y atención 2020</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Imagen corporal VBG de tipo psicológica 2019</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Atención integral a la población víctima del conflicto armado 2019</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Violencia de noviazgo 2019-2020</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Violencia de género en jóvenes 2019</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Violencia contra la mujer Marsella 2018</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Violencia región del eje cafetero 2018-2020</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Violencia conyugal 2018-2019</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Violencia ocupacional 2017</h4>
                            </a>
                        </div>
                    </div>

                    <div className="academia-table-item">
                        <div className="academia-table-title">
                            <h4>Autonomía física</h4>
                        </div>
                        <div className="academia-table-document">
                            <a href="/">
                                <h4>Cancepciones docentes de la violencia contra la mujer 2015</h4>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}*/

export default BodyAcademia;