import React from "react";
import BannerIndicadores from "../pageComponents/BannerIndicadores";
import BodyIndicadores from "../pageComponents/BodyIndicadores";

const Indicadores = () => {
    return (
        <>
            <BannerIndicadores />
            <BodyIndicadores />
        </>
    );
  };
  
export default Indicadores;