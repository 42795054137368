import React, { useState } from "react";
import './BodyPoliticas.css';

const BodyPoliticas = () => {
    
    const [toggleState, setToggleState] = useState(0);
    const toggleEntity = (index) => {
            setToggleState(index)
        }

    return(
        <div className="body-politicas">

            <div className="body-politicas-text">
                <h2> Políticas públicas de equidad de género en el departamento</h2>
                <br/>
            </div>
            <br/>
            <div className="body-politicas-table">

                <div className="main-panel">
                    <div className="left-panel">
                        <div className="politicas-table-title table-title">
                            <h3>Consulte aquí haciendo click</h3>
                        </div>                    

                        <div className = {toggleState === 0 ? 'left-panel-item active' : 'left-panel-item'} 
                                onClick={() => toggleEntity(0)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Escudo_de_Risaralda.svg/1200px-Escudo_de_Risaralda.svg.png" 
                                      alt="escudo-risaralda" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Departamental</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 1 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(1)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Escudo_pereira.svg" 
                                     alt="escudo-pereira" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Pereira</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 2 ? 'left-panel-item active' : 'left-panel-item'} 
                            onClick={() => toggleEntity(2)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Escudo_de_Ap%C3%ADa_-_Risaralda.svg/1826px-Escudo_de_Ap%C3%ADa_-_Risaralda.svg.png" 
                                     alt="escudo-apia" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Apía</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 3 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(3)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Escudo_de_Balboa_-_Risaralda.svg" 
                                     alt="escudo-balboa" 
                                     className="balboa"/>
                            </div>
                            <div className="left-panel-item-nombre balboa">
                                <h4>Balboa</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 4 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(4)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/e/ee/Escudo_de_Bel%C3%A9n_de_Umbr%C3%ADa_-_Risaralda.svg" 
                                     alt="escudo-belen-de-umbria" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Belén de Umbría</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 5 ? 'left-panel-item active' : 'left-panel-item'} 
                                onClick={() => toggleEntity(5)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Escudo_de_Dosquebradas_%28Risaralda%29.svg/500px-Escudo_de_Dosquebradas_%28Risaralda%29.svg.png" 
                                     alt="escudo-dosquebradas" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Dosquebradas</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 6 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(6)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/b/b8/Escudo_de_Gu%C3%A1tica_-_Risaralda.svg" 
                                     alt="escudo-guatica" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Guática</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 7 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(7)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/0/01/Escudo_La_Celia_Risaralda.png" 
                                     alt="escudo-la-celia" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>La Celia</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 8 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(8)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Escudo_de_La_Virginia.svg/1200px-Escudo_de_La_Virginia.svg.png" 
                                     alt="escudo-la-virginia" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>La Virginia</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 9 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(9)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Escudo_de_Marsella_%28Risaralda%29.svg/1200px-Escudo_de_Marsella_%28Risaralda%29.svg.png" 
                                     alt="escudo-marsella" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Marsella</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 10 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(10)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/f/f3/Escudo_de_Mistrat%C3%B3_-_Risaralda.svg" 
                                     alt="escudo-mistrato" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Mistrató</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 11 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(11)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Escudo_de_Pueblorrico.svg/1010px-Escudo_de_Pueblorrico.svg.png" 
                                     alt="escudo-pueblo-rico" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Pueblo Rico</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 12 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(12)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/0/07/ESCUDO_DE_QUINCHIA.png" 
                                     alt="escudo-quinchia" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Quinchía</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 13 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(13)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Escudo_de_Santa_Rosa_de_Cabal.svg/1396px-Escudo_de_Santa_Rosa_de_Cabal.svg.png" 
                                     alt="escudo-santa-rosa-de-cabal" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Santa Rosa de Cabal</h4>
                            </div>
                        </div>

                        <div className = {toggleState === 14 ? 'left-panel-item active' : 'left-panel-item'} 
                             onClick={() => toggleEntity(14)} >
                            <div className="left-panel-item-escudo">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Escudo_de_El_Santuario.svg/564px-Escudo_de_El_Santuario.svg.png" 
                                     alt="escudo-santuario" />
                            </div>
                            <div className="left-panel-item-nombre">
                                <h4>Santuario</h4>
                            </div>
                        </div>

                    </div>

                    <div className="panel-publicaciones">
                        <div className="politicas-table-document table-header">
                            <div className="politicas-table-document-title">
                                <h3>DOCUMENTOS</h3>
                            </div>
                            <div className="politicas-table-document-title">
                                <h3>Descargar</h3>
                            </div>
                         </div>
                        <div className={ toggleState === 0 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Política pública departamental de equidad de género para la mujer
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1jCjtCOrZemOMvDKjWo4gdLwi4fAAPPBs/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 0 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Plan estratégico p.p equidad de género 2023-2033
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1mXeY9C2RGUUWlKjc-P8cFQk8_Jjj_7sG/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 0 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Ajuste y actualización p.p equidad de género para las mujeres
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/14Zp3mi-Vsp5TTKTFEYuAIytI2RfqAYYP/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 0 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Política pública equidad género
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1C1IHviVegxXVqX4r_9N5M9ssukIOiwbq/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className={ toggleState === 1 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe de seguimiento a p.p equidad de género y vigencia 2021
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1qqZ6goOqOSkB4W8I483RmtDLQuEs3OI4/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 1 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe de seguimiento a p.p equidad de género y vigencia 2020
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1yh9TIDxJlP-tv3Y-dFZD4Mo1J1VrluI-/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 1 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Documento técnico equidad de género
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1TyjHCgw85pfV_l65H33mxFk6M_hashkm/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className={ toggleState === 2 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </div>
                        </div>

                        <div className={ toggleState === 3 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </div>
                        </div>

                        <div className={ toggleState === 4 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </div>
                        </div>

                        <div className={ toggleState === 5 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe de seguimiento p.p equidad de género 2022(vigente)
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1Z_vR3xOaORZdrE8Ugs_iQTiLSHxTTDli/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 5 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe de seguimiento p.p equidad de género 2021
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1-Tl79OQl5pkyM-N2QmOZPK_nTtWaUd9_/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 5 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Documento técnico equidad de género
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/10afb46FALlmsuObz3xLWtg8mqXUQNg0-/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className={ toggleState === 6 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </div>
                        </div>

                        <div className={ toggleState === 7 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </div>
                        </div>

                        <div className={ toggleState === 8 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Acuerdo 004 de 2021
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1uIqNK8uoUOj4n_nlmIC0RisknDigQ0aE/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        
                        <div className={ toggleState === 9 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </div>
                        </div>

                        <div className={ toggleState === 10 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </div>
                        </div>

                        <div className={ toggleState === 11 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Política acuerdo de equidad de género 2023
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1wrOum03pDRgM1G_timb6VNfnUyWAGhAv/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 11 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Acuerdo equidad de género Pueblo Rico
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1YMYl5B8LuI12MhUpzvQBmta6n2ryAp7E/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className={ toggleState === 12 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Acuerdo equidad de género Quinchía
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/188GfuOg6s8Lo8DRzy6yovC47F1OJhHK0/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className={ toggleState === 13 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Tercer seguimiento a p.p MYEG control político 2023
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1Ok4p_sZyzVy-OvBmaAFrmKcVq0oIkpFj/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        <div className={ toggleState === 13 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Acuerdo 14 del 23 septiembre de 2022 - p.p mujer y equidad de género
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1DOdl9HnOfqHOcBdMxj3lCshpbproHsOl/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className={ toggleState === 14 ? 'panel-publicaciones-item active' : 'panel-publicaciones-item'}>
                            <div className="publicaciones-item-text">
                                <h4>
                                    Acuerdo equidad de género para la mujer E.G.M
                                </h4>
                            </div>
                            <div className="publicaciones-item-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1C6ohlWHkIJUYDmMS1ai2vz3SAhVCbITv/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

/*class BodyPoliticas extends Component {
    
    render() {
        
        return(
            <div className="body-politicas">

                <div className="body-politicas-text">
                    <h2> Risaralda </h2>
                    <br/>
                </div>
                <br/>
                <br/>
                <div className="body-politicas-table">

                    <div className="politicas-table-item">
                        {/*<div className="politicas-table-title table-title">
                            <h3>MUNICIPIO</h3>
                        </div>
                        <div className="politicas-table-document table-header">
                            <h3>DOCUMENTOS</h3>
                        </div>*/
                    {/*</div>
                    <div className="main-panel">
                        <div className="left-panel">
                            <div className="politicas-table-title table-title">
                                <h3>MUNICIPIO</h3>
                            </div>
                            <div className="left-panel-row row-depto">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Escudo_de_Risaralda.svg/1200px-Escudo_de_Risaralda.svg.png" 
                                             alt="escudo-risaralda" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Departamental</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="left-panel-row">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Escudo_pereira.svg" 
                                             alt="escudo-pereira" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Pereira</h5>
                                    </div>
                                </div>

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Escudo_de_Ap%C3%ADa_-_Risaralda.svg/1826px-Escudo_de_Ap%C3%ADa_-_Risaralda.svg.png" 
                                             alt="escudo-apia" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Apía</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="left-panel-row">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Escudo_de_Balboa_-_Risaralda.svg" 
                                             alt="escudo-balboa" 
                                             className="balboa"/>
                                    </div>
                                    <div className="left-panel-item-nombre balboa">
                                        <h5>Balboa</h5>
                                    </div>
                                </div>

                                <div className="left-panel-item">
                                   <div className="left-panel-item-escudo">
                                       <img src="https://upload.wikimedia.org/wikipedia/commons/e/ee/Escudo_de_Bel%C3%A9n_de_Umbr%C3%ADa_-_Risaralda.svg" 
                                            alt="escudo-belen-de-umbria" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Belén de Umbría</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="left-panel-row">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Escudo_de_Dosquebradas_%28Risaralda%29.svg/500px-Escudo_de_Dosquebradas_%28Risaralda%29.svg.png" 
                                             alt="escudo-dosquebradas" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Dosquebradas</h5>
                                    </div>
                                </div>

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/b/b8/Escudo_de_Gu%C3%A1tica_-_Risaralda.svg" 
                                             alt="escudo-guatica" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Guatica</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="left-panel-row">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/0/01/Escudo_La_Celia_Risaralda.png" 
                                             alt="escudo-la-celia" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>La Celia</h5>
                                    </div>
                                </div>

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Escudo_de_La_Virginia.svg/1200px-Escudo_de_La_Virginia.svg.png" 
                                             alt="escudo-la-virginia" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>La Virginia</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="left-panel-row">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Escudo_de_Marsella_%28Risaralda%29.svg/1200px-Escudo_de_Marsella_%28Risaralda%29.svg.png" 
                                             alt="escudo-marsella" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Marsella</h5>
                                    </div>
                                </div>

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/f/f3/Escudo_de_Mistrat%C3%B3_-_Risaralda.svg" 
                                             alt="escudo-mistrato" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Mistrató</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="left-panel-row">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Escudo_de_Pueblorrico.svg/1010px-Escudo_de_Pueblorrico.svg.png" 
                                             alt="escudo-pueblo-rico" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Pueblo Rico</h5>
                                    </div>
                                </div>

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/0/07/ESCUDO_DE_QUINCHIA.png" 
                                             alt="escudo-quinchia" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Quinchía</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="left-panel-row">

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Escudo_de_Santa_Rosa_de_Cabal.svg/1396px-Escudo_de_Santa_Rosa_de_Cabal.svg.png" 
                                             alt="escudo-santa-rosa-de-cabal" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Santa Rosa de Cabal</h5>
                                    </div>
                                </div>

                                <div className="left-panel-item">
                                    <div className="left-panel-item-escudo">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Escudo_de_El_Santuario.svg/564px-Escudo_de_El_Santuario.svg.png" 
                                             alt="escudo-santuario" />
                                    </div>
                                    <div className="left-panel-item-nombre">
                                        <h5>Santuario</h5>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="panel-publicaciones">
                            <div className="politicas-table-document table-header">
                                <h3>DOCUMENTOS</h3>
                             </div>
                            <div className="panel-publicaciones-item">
                                <a href="/">
                                    <h4>
                                        Política pública departamental de equidad de género para la mujer
                                    </h4>
                                </a>
                            </div>
                        </div>
                    </div>*/}




                    {/*<div className="politicas-table-item">
                        <div className="politicas-table-title">
                            <h3>Departamental</h3>
                        </div>
                        <div className="politicas-table-document">
                            <a href="/">
                                <h4>
                                    Política pública departamental de equidad de género para la mujer
                                </h4>
                            </a>
                            <a href="/">
                                <h4>
                                    Plan estratégico p.p equidad de género 2023-2033
                                </h4>
                            </a>
                            <a href="/">
                                <h4>
                                    Ajuste y actualización p.p equidad de género para las mujeres
                                </h4>
                            </a>
                            <a href="/">
                                <h4>
                                    Política pública equidad género
                                </h4>
                            </a>
                        </div>
                    </div>

                    <div className="politicas-table-item">
                        <div className="politicas-table-title">
                            <h3>Pereira</h3>
                        </div>
                        <div className="politicas-table-document">
                            <a href="/">
                                <h4>
                                    Informe de seguimiento a p.p equidad de género y vigencia 2021
                                </h4>
                            </a>
                            <a href="/">
                                <h4>
                                    Documento técnico equidad de género
                                </h4>
                            </a>
                        </div>
                    </div>

                    <div className="politicas-table-item">
                        <div className="politicas-table-title">
                            <h3>Apía</h3>
                        </div>
                        <div className="politicas-table-document">
                            <a href="/">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </a>
                        </div>
                    </div>

                    <div className="politicas-table-item">
                        <div className="politicas-table-title">
                            <h3>Dosquebradas</h3>
                        </div>
                        <div className="politicas-table-document">
                            <a href="/">
                                <h4>
                                    Informe de seguimiento p.p equidad de género 2022(vigente)
                                </h4>
                            </a>
                            <a href="/">
                                <h4>
                                    Documento técnico equidad de género
                                </h4>
                            </a>
                        </div>
                    </div>

                    <div className="politicas-table-item">
                        <div className="politicas-table-title">
                            <h3>La Virginia</h3>
                        </div>
                        <div className="politicas-table-document">
                            <a href="/">
                                <h4>
                                    Informe no disponible
                                </h4>
                            </a>
                        </div>
                    </div>

                    <div className="politicas-table-item">
                        <div className="politicas-table-title">
                            <h3>Santa Rosa</h3>
                        </div>
                        <div className="politicas-table-document">
                            <a href="/">
                                <h4>
                                    Tercer seguimiento a p.p MYEG control político 2023
                                </h4>
                            </a>
                            <a href="/">
                                <h4>
                                    Acuerdo 14 del 23 septiembre de 2022 - p.p mujer y equidad de género
                                </h4>
                            </a>
                        </div>
                    </div>

                    <div className="politicas-table-item">
                        <div className="politicas-table-title">
                            <h3>Santuario</h3>
                        </div>
                        <div className="politicas-table-document">
                            <a href="/">
                                <h4>
                                    Acuerdo equidad de género para la mujer
                                </h4>
                            </a>
                        </div>
                    </div> */}   

{/*                </div>
            </div>
        );
    }
}*/}

export default BodyPoliticas;
