import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register (
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

var mujeres = [22130, 20157, 32412, 29493, 25348];
var hombres = [16315, 16940, 31751, 30400, 23660];
var años = ['2018', '2019', '2020', '2021', '2022']

var midata = {
    labels: años,
    datasets: [//Cada una de las lineas del grafico
        {
            label: 'Mujeres',
            data: mujeres,
            backgroundColor: 'rgba(72, 43, 138, 0.8)',
        },
        {
            label: 'Hombres',
            data: hombres,
            backgroundColor: 'rgba(50, 133, 123, 0.8)',
        },
        

    ]

}

var misoptions = {
    response: true,
    animation: false,
    plugins: {
        legend: {
            display: true
        }
    },
    scales: {
        y: {
            min: 0,
            max: 35000,
            title: {
                display: true,
                text: 'Desempleados'
            }
        },
        x: {
            ticks: {color: 'gray'},
            title: {
                display: true,
                text: 'Año'
            }
        }
    }
}

export default function BarsChartDesemp() {
    return <Bar data={midata} options={misoptions} />
}