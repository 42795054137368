import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Footer.css";
/*import logoFooter from "../images/LOGO OBSERVATORIO.png";*/
import logoFooter from "../images/LOGOfooter.png";
import escudoRis from "../images/escudoRisaralda-blanco.png";

function Footer(){

    return (
        <div className="footer">
            <div className="footer-logos">
                <div className="footer-logos-gob">
                    <img src={escudoRis} alt="escudo-risaralda" />
                </div>
                <h3>Gobernación De Risaralda</h3>
                <div className="footer-logos-oas">
                    <img src={logoFooter} alt="logo-OAS-risaralda"/>
                </div>
            </div>
            <div className="footer-text">
                <h4>Contáctenos</h4>
                <div className="footer-text-item">
                    <h5>Tel: 606 3398300 ext 319</h5>
                    <i class="fa-solid fa-phone"></i>
                </div>
                <div className="footer-text-item">
                    <h5>observatoriorisaraldamujer@gmail.com</h5>
                    <i class="fa-solid fa-envelope"></i>
                </div>
                <div className="footer-text-item">
                    <h5>mujeryasuntosdegenero@risaralda.gov.co</h5>
                    <i class="fa-solid fa-envelope"></i>
                </div>
                <div className="footer-text-item">
                    <h5>Calle 19 No. 13-17 Parque Olaya Herrera - Pereira</h5>
                    <i class="fa-solid fa-location-dot"></i>
                </div>
            </div>
        </div>
    )
}

export default Footer;