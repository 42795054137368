import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

var options = {
    responsive: true,
    maintainAspectRatio: true
}

var data = {
    
    labels : ['TD Mujeres', 'TD Hombres', 'Brecha Tasa de Desempleo'],
    datasets: [
        {
            label: 'Tasas de desempleo',
            data : [17.99, 12.77, 14.55],
            backgroundColor : [
                'rgba(72, 43, 138, 0.6)',
                'rgba(50, 133, 123, 0.6)',
                'rgba(255, 206, 86, 0.6)',
            ],
            borderColor : [
                'rgba(72, 43, 138, 1)',
                'rgba(50, 133, 123, 1)',
                'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1
        }
    ]
    
}

export default function PieChartDesemp() {
    return <Pie data={data} options={options} />
}