import React, { Component } from "react";
import './BannerEventos.css';

class BannerEventos extends Component {

    render() { 
        return(
            <div className="banner-eventos">
                <h1> NOTICIAS / EVENTOS </h1>
            </div>
        );
    }
}

export default BannerEventos;