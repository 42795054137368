import React from "react";
import BannerAcademia from "../pageComponents/BannerAcademia";
import BodyAcademia from "../pageComponents/BodyAcademia"; 

const Academia = () => {
    return (
        <>
            <BannerAcademia />
            <BodyAcademia />
        </>
    );
  };
  
export default Academia;