import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

var options = {
    responsive: true,
    maintainAspectRatio: true
}

var data = {
    
    labels : ['Casos mujeres', 'Casos hombres'],
    datasets: [
        {
            label: 'año 2021',
            data : [6, 3],
            backgroundColor : [
                'rgba(72, 43, 138, 0.6)',
                'rgba(50, 133, 123, 0.6)',
            ],
            borderColor : [
                'rgba(72, 43, 138, 1)',
                'rgba(50, 133, 123, 1)',
            ],
            borderWidth: 1
        }
    ]
    
}

export default function PieChartFeminici() {
    return <Pie data={data} options={options} />
}