import React from "react";
import BannerEventos from "../pageComponents/BannerEventos";
import BodyEventos from "../pageComponents/BodyEventos";

const Eventos = () => {
    return (
        <>
            <BannerEventos />
            <BodyEventos />
        </>
    )
  };
  
export default Eventos;