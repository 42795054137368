import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import '../pageComponents/Acceso.css';

/*export default function Acceso(){

        <h1 className="acceso"> Acceso </h1>

}*/

const Acceso = () => {

  const [formData, setFormData] = useState({
    name: 'user',
    password: 0,
    timestamp: new Date().toISOString()
  })

const handleSubmit = () => {
    console.log('Submitted');
}

const handleChange = (e) => {
    //console.log('Changed');
    const value = e.target.value
    const name = e.target.name

    setFormData((prevState) => ({
        ...prevState,
        [name]: value 
        })
    )
}
    
console.log(formData);

  return (
    <div className="acceso">
      <div className="acceso-text">
        <h3>Inicie session como administrador</h3>
      </div>

      <br/>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Usuario</label>
          <input
            id= "name"
            name= "name"
            type= "text"
            onChange = {handleChange} 
            required = {true}
          />
          <label htmlFor="password">Contraseña</label>
          <input
            id= "password"
            name= "password"
            type= "password"
            onChange = {handleChange} 
            required = {true}
          />
      </form>
      <Link to="/config" >
        <input className="access-button" value='Acceder' type="submit"/>
      </Link>
    </div>
  )
};
  
export default Acceso;