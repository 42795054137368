import React, { Component } from "react";
import './BannerBoletines.css';

class BannerBoletines extends Component {
    render() { 
        return(
            <div className="banner-boletines">
                <h1> BOLETINES </h1>
            </div>
        );
    }
}

export default BannerBoletines;