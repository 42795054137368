import React, { Component } from "react";
import LogoObs from "../../images/LOGO OBSERVATORIO.png";
import './BodyHome.css';

class BodyHome extends Component {
    render() { 
        return(
            <div className="body-home">
                <div className="body-home-text">
                    <h2> QUIÉNES SOMOS </h2>
                    <br/>
                    <p> El Observatorio de asuntos de género (OAG) del departamento de Risaralda, 
                    nace en el marco de la implementación de la Política Pública de Equidad de Género 
                    para las mujeres Risaralda Mujer, aprobada mediante Ordenanza 005 de 2015 y 
                    en respuesta a las acciones afirmativas de las organizaciones de mujeres e 
                    instituciones que en el departamento buscan promover el cierre de brechas de género 
                    y garantizar el avance de las mujeres. 
                    </p>
                    <br/>
                    <p> La finalidad del OAG es identificar y seleccionar un sistema de indicadores de género, 
                    categorías de análisis y mecanismos de seguimiento para hacer reflexiones críticas 
                    sobre las políticas, los planes, los programas, las normas, la jurisprudencia para el 
                    mejoramiento de la situación de las mujeres y la equidad de género en el departamento.
                    </p>
                </div>
            </div>
        );
    }
}

export default BodyHome