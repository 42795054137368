import React, { Component } from 'react';
import './App.css';
import Navbar from "./components/Navbar";  
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./components/pages/Home";
import Acceso from "./components/pages/Acceso";
import Configuracion from './components/pages/Configuracion';
import Contactenos from "./components/pages/Contactenos";
import Eventos from "./components/pages/Eventos";
import Indicadores from "./components/pages/Indicadores";
import Normatividad from "./components/pages/Normatividad";
import Politicas from "./components/pages/Politicas";
import Publicaciones from "./components/pages/Publicaciones";
import Academia from './components/pages/Academia';
import Proyectos from './components/pages/Proyectos';
import Footer from './components/Footer';
import Boletines from './components/pages/Boletines';

class App extends Component{
  handleClick(e){
    this.setState({clicked: true});
  }
  render() {
    return (
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/contactenos' exact element={<Contactenos />} />
          <Route path='/eventos' exact element={<Eventos />} />
          <Route path='/acceso' exact element={<Acceso />} />
          <Route path='/config' exact element={<Configuracion />} />
          <Route path='/academia' exact element={<Academia />} />
          <Route path='/indicadores' exact element={<Indicadores />} />
          <Route path='/normatividad' exact element={<Normatividad />} />
          <Route path='/politicas' exact element={<Politicas />} />
          <Route path='/proyectos' exact element={<Proyectos />} />
          <Route path='/publicaciones' exact element={<Publicaciones />} />
          <Route path='/boletines' exact element={<Boletines />} />
        </Routes>
        <Footer />
      </Router>
    );
  }
}

export default App;
