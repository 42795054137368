import React from "react";
import BannerPublicaciones from "../pageComponents/BannerPublicaciones";
import BodyPublicaciones from "../pageComponents/BodyPublicaciones";

const Publicaciones = () => {
    return (
        <>
            <BannerPublicaciones />
            <BodyPublicaciones />
        </>
    )
  };
  
export default Publicaciones;