import React, { Component } from "react";
import './BannerAcademia.css';


class BannerAcademia extends Component {
    render() { 
        return(
            <div className="banner-academia">
                <h1> ACADEMIA </h1>
            </div>
        );
    }
}

export default BannerAcademia;