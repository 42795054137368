import React, { Component } from "react";
import './BannerProyectos.css';

class BannerProyectos extends Component {
    render() { 
        return(
            <div className="banner-proyectos">
                <h1> PROYECTOS </h1>
            </div>
        );
    }
}

export default BannerProyectos;