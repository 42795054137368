import React, { Component, useState } from "react";
import './BodyPublicaciones.css';
import LogoObs from "../../images/LOGO OBSERVATORIO.png";

const BodyPublicaciones = () => {
    const[toggleState, setToggleState] = useState(0);
    var Comision = 0

    const toggleEntity = (index) => {
        setToggleState(index);
    }
        
    const toggleComision = (index) => {
        Comision = index;
        console.log(Comision);
    }
    
    return(
        <div className="body-publicaciones">
            <div className="body-publicaciones-text">
                <h2> Publicaciones </h2>
                <br/>
            </div>
            <br/>

            <div className="main-panel">

                <div className="left-panel">
                    <div className="panel-title">
                        <h3 className="left-panel-title">
                            Entidad
                        </h3>
                    </div>
                    <div className = { toggleState === 0 ? "left-panel-entidad selected" : "left-panel-entidad"}
                         onClick={() => toggleEntity(0)}>
                        <div className="entidad-logo">
                            <img src="https://logowik.com/content/uploads/images/registraduria-nacional-del-estado-civil-colombiana7696.logowik.com.webp" 
                                 alt="registraduría-logo"/>
                        </div>
                        <h4>Registraduría Nacional</h4>
                    </div>

                    <div className = { toggleState === 1 ? "left-panel-entidad selected" : "left-panel-entidad"}
                         onClick={() => toggleEntity(1)}>
                        <div className="entidad-logo">
                            <img src="https://yt3.googleusercontent.com/ytc/APkrFKZ0FgPY0532aAaLaOJ_If7ZE8ZkWPFFlcM8agIY=s900-c-k-c0x00ffffff-no-rj"
                                alt="onu-mujeres-logo"/>
                        </div>
                        <h4>ONU Mujeres</h4>
                    </div>

                    <div className = { toggleState === 2 ? "left-panel-entidad selected" : "left-panel-entidad"}
                         onClick={() => toggleEntity(2)}>
                        <div className="entidad-logo">
                            {/*<img src="https://scontent.fpei3-1.fna.fbcdn.net/v/t39.30808-6/302451581_389381196722646_142358404606057710_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=efb6e6&_nc_eui2=AeGDiFc1PZ_VdzLzrri1Y2Gx-H_VjjgvpIv4f9WOOC-kizbblfm8CTmUPR6T1Hux63g&_nc_ohc=HKk8DoPre7EAX_Dcpwc&_nc_ht=scontent.fpei3-1.fna&oh=00_AfDwPvrMg3xYBAPDIJyDek5F3R0baUrmzipaVXn2o_BcAQ&oe=65752797" 
                                 alt="observatorio-colombiano-mujeres-logo"/>*/}
                                 <img src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Coat_of_arms_of_Colombia.svg"
                                      alt="escudo-colombia" />
                        </div>
                        <h4>Observatorio Nacional <br /> de las Mujeres</h4>
                    </div>

                    <div className = { toggleState === 7 ? "left-panel-entidad selected" : "left-panel-entidad"}
                         onClick={() => toggleEntity(7)}>
                        <div className="entidad-logo">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Escudo_de_Colombia.svg/2014px-Escudo_de_Colombia.svg.png" alt="funcion-publica" />
                        </div>
                        <h4>Función Pública</h4>
                    </div>

                    <div className = { toggleState === 3 ? "left-panel-entidad selected" : "left-panel-entidad"}
                         onClick={() => toggleEntity(3)}>
                        <div className="entidad-logo">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Colombia_Dane_logo_%282%29.svg/2560px-Colombia_Dane_logo_%282%29.svg.png" 
                                 alt="dane-colombia-logo"/>
                        </div>
                        <h4>DANE Colombia</h4>
                    </div>

                    <div className = { toggleState === 4 ? "left-panel-entidad comisiones selected" : "left-panel-entidad comisiones"}
                         onClick={() => toggleEntity(4)}>
                        <div className="entidad-comisiones-title">
                            <div className="entidad-logo">
                                <img src="https://cdn-icons-png.flaticon.com/512/32/32353.png"
                                    alt="logo-mujeres"/>
                            </div>
                            <h4>Comisiones De Género</h4>
                            {/*<i class="fa-solid fa-caret-down icon"></i>*/}
                        </div>
                        {/*<ul className="entidad-comisiones-list">
                            <li className = { toggleState === 4 && toggleComision === 0 ? "left-panel-entidad-comision selected" : "left-panel-entidad-comision"}
                                onClick={() => toggleComision(0)}>
                                <h5>Rama legislativa</h5>
                            </li>
                            <li className = { toggleState === 4 && toggleComision === 1 ? "left-panel-entidad-comision selected" : "left-panel-entidad-comision"}
                                onClick={() => toggleComision(1)}>
                                <h5>Rama ejecutiva</h5>
                            </li>
                            <li className = { toggleState === 4 && toggleComision === 2 ? "left-panel-entidad-comision selected" : "left-panel-entidad-comision"}
                                onClick={() => toggleComision(2)}>
                                <h5>Rama judicial</h5>
                            </li>
                        </ul>*/}
                    </div>

                    <div className = { toggleState === 5 ? "left-panel-entidad selected" : "left-panel-entidad"}
                         onClick={() => toggleEntity(5)}>
                        <div className="entidad-logo">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Logo_de_la_Comisi%C3%B3n_Econ%C3%B3mica_para_Am%C3%A9rica_Latina_y_el_Caribe.svg/1200px-Logo_de_la_Comisi%C3%B3n_Econ%C3%B3mica_para_Am%C3%A9rica_Latina_y_el_Caribe.svg.png" 
                                 alt="cepal-logo"/>
                        </div>
                        <h4>CEPAL</h4>
                    </div>

                    <div className = { toggleState === 6 ? "left-panel-entidad selected" : "left-panel-entidad"}
                         onClick={() => toggleEntity(6)}>
                        <div className="entidad-logo">
                            <img src={LogoObs} alt="oag-logo" />
                        </div>
                        <h4>OAG Risaralda</h4>
                    </div>

                </div>

                <div className="files-panel">

                    <div className="files-bar">
                        <div className="files-title">
                            <h3>Título</h3>
                        </div>
                        <div className="files-title">
                            <h3>Descargar</h3>
                        </div>
                    </div>
                    
                    <div className = { toggleState === 0 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Balance de gobernadoras y alcaldesas electas</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1yI8NTtZFDrO-bGrtIX68LXBRF7KGSkl5/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                    </div>
                    
                    <div className = { toggleState === 1 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Guía práctica para la investigación del feminicidio I </h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1ZmN7HpfwdyeAUL5h2cxaf04bPpoSyRIc/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Guía práctica para la investigación del feminicidio II </h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1rxvQ-0aOJ1upaRcSQQWD4NSBzxWPraYv/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Guía práctica para la investigación del feminicidio III </h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1RGFg7cg8i26v6CMw7Q7_xnWN6UVW7xV2/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Mujeres y hombres: Brechas de género en Colombia</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1Ff166npw5gJdCTftsHkk0h3GBDN50te5/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>ABC del documental</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1s6fgyaXtlRuQ_WbnDgB1R-JK7zdtoALW/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                    </div>

                    <div className = { toggleState === 2 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Atlas productivo de las mujeres en Colombia</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1hlpW9BxllBFPzDyKprZfB9FF66JN3Kh2/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Atlas político de las mujeres en Colombia</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1CKL5EM7-Reqw-Gvk5DzmryJWPXXvrgkP/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Atlas socioeconómico de las mujeres en Colombia</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1Ez4WAW0YL8YiA1U8Bad-Z04qDh21Ae-q/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                    </div>

                    <div className = { toggleState === 3 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Brecha salarial de género en Colombia</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1gdzSxO4nTrE_pxyfwIspC7js1BpXF16G/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                    </div>

                    <div className = { toggleState === 4 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        
                        {/*<div className = { toggleState === 4 && Comision === 0 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>

                        </div>
                        
                        <div className = { toggleState === 4 && Comision === 1 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>

                        </div>*/}

                        <div className = { toggleState === 4 && Comision === 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                            <div className="publicaciones-table-document">
                                <h4>Justicia constitucional, mujeres y género</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1y35AY7Dadvv_duqRQpBsG84znRhRL3Ne/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className = { toggleState === 4 && Comision === 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>   
                            <div className="publicaciones-table-document">
                                <h4>Disertación sobre las mujeres y el "pato salvaje"</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/drive/folders/1ylX4DVY5nSqplanyMQW8F34bcy_NjnEq"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className = { toggleState === 4 && Comision === 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                            <div className="publicaciones-table-document">
                                <h4>Lísta de verificación</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1IJoFdI44KEFw0DCilCE-9tegh8WzFQ-E/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className = { toggleState === 4 && Comision === 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                            <div className="publicaciones-table-document">
                                <h4>Los derechos de las mujeres y la perspectiva de género </h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1cuyZ6mlhfj570wHMojXsYKAaPdiCfOOf/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className = { toggleState === 4 && Comision === 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                            <div className="publicaciones-table-document">
                                <h4>Reparación del daño desde el enfoque diferencial de género</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1VYCySkkMc-XdBsgbf_7YEezlKYEZGTqZ/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                    </div>  

                    <div className = { toggleState === 5 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Romper el silencio estadístico para alcanzar la igualdad de género 2023</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/176BtiNe0AUgwzeiSqEGAA2ubcDU0-756/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Manual: formulación, evaluación y monitoreo de proyectos sociales</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/10Uwrb6vyzpiKI3JjwWWZpvFiJ4lPFiYL/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>La sociedad del ciudadano: Horizonte para una recuperación sostenible con igualdad de género</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1UYgMFm3WaCe24e_gEmoR24Fue1v5yuzQ/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>El financiamiento de los sistemas y políticas de cuidados en America Latina y el Caribe</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1tF4Q2eGdC5DQAbMrIihodNU66bVQv6gZ/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Avances de normativa del cuidado en America Latina y el Caribe</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1CX-I0MxmRkpcJhb3pjguv6avZ57qps18/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Boletín CEPAL 2023</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/11l9CTVcLwEFvtKqdXCxXJqzscEJVyYon/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                    </div>

                    <div className = { toggleState === 6 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Informe no disponible</h4>
                            </div>
                        </div>
                    </div>

                    <div className = { toggleState === 7 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                        
                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Informe de ley de cuotas 2022</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/189e32SL7GFHLopg_K6cnDIFrKp2rphPJ/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Informe de ley de cuotas 2021</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1URU3fxy7kaEjrRynqP0XHCRDXovozlrq/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Informe de ley de cuotas 2020</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1MwKzIebWAEIBVkrQkbi4Mv2ZDiV0qhOz/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                        <div className="publicaciones-item-entidad">
                            <div className="publicaciones-table-document">
                                <h4>Informe de ley de cuotas 2019</h4>
                            </div>
                            <div className="publicaciones-table-icon">
                                <a target="blank" href="https://drive.google.com/file/d/1ZAWl-qkBbOV4TUNLGK75q_wMFq4_TO3T/view"><i class="fa-solid fa-download"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
    
}

/*class BodyPublicaciones extends Component {
    render(){
        
        const[toggleState, setToggleState] = useState(1);
        var Comision = 0

        const toggleEntity = (index) => {
            setToggleState(index);
        }
        
        const toggleComision = (index) => {
            Comision = index;
        } 

        return(
            <div className="body-publicaciones">
                <div className="body-publicaciones-text">
                    <h2> Risaralda </h2>
                    <br/>
                </div>
                <br/>

                <div className="main-panel">

                    <div className="left-panel">
                        <h3 className="left-panel-title">
                            Entidad
                        </h3>

                        <div className = { toggleState === 0 ? "left-panel-entidad selected" : "left-panel-entidad"}
                             onClick={() => toggleEntity(0)}>
                            <h4>Registraduría Nacional</h4>
                            <div className="entidad-logo"></div>
                        </div>

                        <div className = { toggleState === 1 ? "left-panel-entidad selected" : "left-panel-entidad"}
                             onClick={() => toggleEntity(1)}>
                            <h4>ONU Mujeres</h4>
                            <div className="entidad-logo"></div>
                        </div>

                        <div className = { toggleState === 2 ? "left-panel-entidad selected" : "left-panel-entidad"}
                             onClick={() => toggleEntity(2)}>
                            <h4>Observatorio nacional de mujeres</h4>
                            <div className="entidad-logo"></div>
                        </div>

                        <div className = { toggleState === 3 ? "left-panel-entidad selected" : "left-panel-entidad"}
                             onClick={() => toggleEntity(3)}>
                            <h4>DANE Colombia</h4>
                            <div className="entidad-logo"></div>
                        </div>

                        <div className = { toggleState === 4 ? "left-panel-entidad selected" : "left-panel-entidad"}
                             onClick={() => toggleEntity(4)}>
                            <h4>Comiciones de género</h4>
                            <i class="fa-solid fa-caret-down"></i>
                            <div className="entidad-logo"></div>
                            <ul>
                                <li className = { toggleState === 4 && toggleComision === 0 ? "left-panel-entidad selected" : "left-panel-entidad"}
                                    onClick={() => toggleComision(0)}>
                                    Rama legislativa
                                </li>
                                <li className = { toggleState === 4 && toggleComision === 1 ? "left-panel-entidad selected" : "left-panel-entidad"}
                                    onClick={() => toggleComision(1)}>
                                    Rama ejecutiva
                                </li>
                                <li className = { toggleState === 4 && toggleComision === 2 ? "left-panel-entidad selected" : "left-panel-entidad"}
                                    onClick={() => toggleComision(2)}>
                                    Rama judicial
                                </li>
                            </ul>
                        </div>

                        <div className = { toggleState === 5 ? "left-panel-entidad selected" : "left-panel-entidad"}
                             onClick={() => toggleEntity(5)}>
                            <h4>CEPAL</h4>
                            <div className="entidad-logo"></div>
                        </div>

                        <div className = { toggleState === 6 ? "left-panel-entidad selected" : "left-panel-entidad"}
                             onClick={() => toggleEntity(6)}>
                            <h4>OAG Risaralda</h4>
                            <div className="entidad-logo"></div>
                        </div>

                    </div>

                    <div className="files-panel">

                        <div className = { toggleState === 0 ? 'publicaciones-items-entidad active' : 'publicaciones-items-entidad'}>
                            
                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Balance de gobernadoras y alcaldesas electas</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>
                        
                        <div className = { toggleState === 1 ? 'publicaciones-items-entidad active' : 'publicaciones-table-entidad'}>
                            
                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Mujeres y hombres: Brechas de género en Colombia</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>ABC del documental</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Guías del feminicidio</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>

                        <div className = { toggleState === 2 ? 'publicaciones-items-entidad active' : 'publicaciones-table-entidad'}>
                            
                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Atlas productivo de las mujeres en Colombia</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Atlas político de las mujeres en Colombia</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Atlas socioeconómico de las mujeres en Colombia</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>

                        <div className = { toggleState === 3 ? 'publicaciones-items-entidad active' : 'publicaciones-table-entidad'}>
                            
                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Brecha salarial de género en Colombia</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>

                        <div className = { toggleState === 4 ? 'publicaciones-items-entidad active' : 'publicaciones-table-entidad'}>
                            
                            <div className = { toggleState == 4 && Comision == 0 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>

                            </div>
                            
                            <div className = { toggleState == 4 && Comision == 1 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>

                            </div>

                            <div className = { toggleState == 4 && Comision == 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                                <div className="publicaciones-table-document">
                                    <h4>Justicia constitucional, mujeres y género</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className = { toggleState == 4 && Comision == 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>   
                                <div className="publicaciones-table-document">
                                    <h4>Disertación sobre las mujeres y el "pato salvaje"</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className = { toggleState == 4 && Comision == 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                                <div className="publicaciones-table-document">
                                    <h4>Linea de verificación</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className = { toggleState == 4 && Comision == 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                                <div className="publicaciones-table-document">
                                    <h4>Los derechos de las mujeres y la perspectiva de género </h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className = { toggleState == 4 && Comision == 2 ? 'publicaciones-item-entidad comision active' : 'publicaciones-item-entidad comision' }>
                                <div className="publicaciones-table-document">
                                    <h4>Reparación del daño desde el enfoque diferencial de género</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>  

                        <div className = { toggleState === 5 ? 'publicaciones-items-entidad active' : 'publicaciones-table-entidad'}>
                            
                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Romper el silencio estadístico para alcanzar la igualdad de género 2023</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Manual: formulación, evaluación y monitoreo de proyectos sociales</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>La sociedad del ciudadano: Horizonte para una recuperación sostenible con igualdad de género</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>El financiamiento de los sistemas y políticas de cuidados en America Latina y el Caribe</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                            <div className="publicaciones-item-entidad">
                                <div className="publicaciones-table-document">
                                    <h4>Avances de normativa del cuidado en America Latina y el Caribe</h4>
                                </div>
                                <div className="publicaciones-table-icon">
                                    <a href="/"><i class="fa-solid fa-download"></i></a>
                                </div>
                            </div>

                        </div>

                        <div className = { toggleState === 6 ? 'publicaciones-items-entidad active' : 'publicaciones-table-entidad'}>
                            <div className="publicaciones-item-entidad"></div>
                        </div>
                    </div>
                </div>

                <div className="body-publicaciones-table">
                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title table-title">
                            <h3>FUENTE</h3>
                        </div>
                        <div className="publicaciones-table-document table-header">
                            <h3>TÍTULO</h3>
                        </div>
                        <div className="publicaciones-table-document table-icon">
                            <h3>DESCARGAR</h3>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Registraduría Nacional Del estado civil y ONU Mujeres</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Balance de gobernadoras y alcaldesas electas</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>ONU Mujeres</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Mujeres y hombres: Brechas de género en Colombia</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>ONU Mujeres</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>ABC del documental</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>
                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>ONU Mujeres</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Guías del feminicidio</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Observatorio nacional de mujeres</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Atlas productivo de las mujeres en Colombia</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Observatorio nacional de mujeres</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Atlas político de las mujeres en Colombia</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Observatorio nacional de mujeres</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Atlas socioeconómico de las mujeres en Colombia</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>DANE Colombia</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Brecha salarial de género en Colombia</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Comisiones de género - Rama judicial</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Justicia constitucional, mujeres y género</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Comisiones de género - Rama judicial</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Disertación sobre las mujeres y el "pato salvaje"</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Comisiones de género - Rama judicial</h4>  
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Linea de verificación</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">  
                            <h4>Comisiones de género - Rama judicial</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Los derechos de las mujeres y la perspectiva de género </h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>Comisiones de género - Rama judicial</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Reparación del daño desde el enfoque diferencial de género</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>CEPAL</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Romper el silencio estadístico para alcanzar la igualdad de género 2023</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>CEPAL</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Manual: formulación, evaluación y monitoreo de proyectos sociales</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>CEPAL</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>La sociedad del ciudadano: Horizonte para una recuperación sostenible con igualdad de género</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>CEPAL</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>El financiamiento de los sistemas y políticas de cuidados en America Latina y el Caribe</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                    <div className="publicaciones-table-item">
                        <div className="publicaciones-table-title">
                            <h4>CEPAL</h4>
                        </div>
                        <div className="publicaciones-table-document">
                            <h4>Avances de normativa del cuidado en America Latina y el Caribe</h4>
                        </div>
                        <div className="publicaciones-table-icon">
                            <a href="/"><i class="fa-solid fa-download"></i></a>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}*/

export default BodyPublicaciones;