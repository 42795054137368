import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register (
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

var priminfancia = [0, 0, 0, 0, 0];
var infancia = [0, 0, 0, 0, 0];
var adolecencia = [0, 1, 0, 0, 1];
var juventud = [2, 3, 0, 5, 1];
var adultez = [4, 3, 0, 4, 0];
var mayor = [0, 0, 0, 0, 0];
var años = ['2018', '2019', '2020', '2021', '2022']

var midata = {
    labels: años,
    datasets: [//Cada una de las lineas del grafico
        {
            label: 'Primera infancia',
            data: priminfancia,
            tension: 0.5,
            fill: true,
            borderColor: 'rgb(255, 92, 132)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            pointRadius: 5,
            pointBorderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgba(0, 0, 0)'
        },

        {
            label: 'Infancia',
            data: infancia,
            tension: 0.5,
            fill: true,
            borderColor: 'rgb(255, 206, 86)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            pointRadius: 5,
            pointBorderColor: 'rgb(255, 206, 86)',
            pointBackgroundColor: 'rgba(0, 0, 0)'
        },
        {
            label: 'Adolecencia',
            data: adolecencia,
            tension: 0.5,
            fill: true,
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            pointRadius: 5,
            pointBorderColor: 'rgba(54, 162, 235)',
            pointBackgroundColor: 'rgba(0, 0, 0)'
        },

        {
            label: 'Juventud',
            data: juventud,
            tension: 0.5,
            fill: true,
            borderColor: 'rgb(64, 172, 245)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            pointRadius: 5,
            pointBorderColor: 'rgb(64, 172, 245)',
            pointBackgroundColor: 'rgba(0, 0, 0)'
        },

        {
            label: 'Adultez',
            data: adultez,
            tension: 0.5,
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            pointRadius: 5,
            pointBorderColor: 'rgb(75, 192, 192)',
            pointBackgroundColor: 'rgba(0, 0, 0)'
        },

        {
            label: 'Mayor',
            data: mayor,
            tension: 0.5,
            fill: true,
            borderColor: 'rgb(153, 102, 255)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            pointRadius: 5,
            pointBorderColor: 'rgb(153, 102, 255)',
            pointBackgroundColor: 'rgba(0, 0, 0)'
        },

    ]

}

var misoptions = {

}

export default function LinesChartFeminici() {
    return <Line data={midata} options={misoptions} />
}