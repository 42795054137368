import React from "react";
import { useState } from "react";
import '../pageComponents/Configuracion.css';

const Configuracion = () => {

    const [formData, setFormData] = useState({
        seccion: 'home',
        updated: false,
        timestamp: new Date().toISOString()
    })

    const handleSubmit = () => {

        console.log('Submitted');
        
        setFormData({updated: true});
        handleClearForm(true);
    }

    const handleClearForm = (saved) => {
        let inputElements = document.getElementsByTagName('input');
        let textAreaElements = document.getElementsByTagName('textarea');
        let checkFields = document.querySelectorAll('[type = "radio"]');
        let i

        for(i = 0; i < inputElements.length ; i++){
            inputElements[i].value = ''
        }

        for(i = 0; i < textAreaElements.length ; i++){
            textAreaElements[i].value = ''
        }

        for(i = 0; i < checkFields.length ; i++){
            checkFields[i].checked = false;
        }

        if(saved === true){
            setFormData({seccion: '', updated: true, timestamp: new Date().toISOString()})
        }else {
            setFormData({seccion: '', timestamp: new Date().toISOString()})
        }

    }

    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const id = e.target.id;

        console.log(name);
        console.log(id);
        
        switch(id){ 

            case 'home':
            case 'indicators':
            case 'academy':
            case 'newsletters':
            case 'normativity':
            case 'news':
            case 'politics':
            case 'publications':
                formData.seccion = id
                break
            
            default:
                break
        }

        switch(name){

            case 'town':
                formData.municipio = id;
                break;

            case 'autonomy':
                formData.autonomia = id;
                break;
            
            case 'scope':
                formData.alcance = id;
                break;

            default: 
                break;
        }

        setFormData((prevState) => ({
            ...prevState,
            [name]: value 
            })
        )
    }

    console.log(formData);
    
    return (
        <div className="configuracion">

            <div className="configuracion-text">
                <h2>Subir o modificar archivos del sitio</h2>
            </div>

            <div className="configuracion-table">
                <div className="left-panel">

                    <div className="left-panel-title">
                        <h3>Sección a editar</h3>
                    </div>

                    <div className="left-panel-item">
                        <div className="left-panel-item-title">
                            <label htmlFor="home">Inicio</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='home'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=" left-panel-item">        
                        <div className="left-panel-item-title">
                            <label htmlFor="indicators">Indicadores</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='indicators'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=" left-panel-item">        
                        <div className="left-panel-item-title">
                            <label htmlFor="academy">Academia</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='academy'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=" left-panel-item">        
                        <div className="left-panel-item-title">
                            <label htmlFor="newsletters"> Boletines</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='newsletters'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=" left-panel-item">        
                        <div className="left-panel-item-title">
                            <label htmlFor="normativity">Normatividad</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='normativity'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=" left-panel-item">        
                        <div className="left-panel-item-title">
                            <label htmlFor="news">Noticias</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='news'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=" left-panel-item">        
                        <div className="left-panel-item-title">
                            <label htmlFor="politics">Políticas</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='politics'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=" left-panel-item">        
                        <div className="left-panel-item-title">
                            <label htmlFor="publications">Publicaciones</label>
                        </div>
                        <div className="left-panel-item-radiobtn">
                            <input 
                                id='publications'
                                name='section'
                                type='radio'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                </div>

                <div className="panel-configuracion">
                    
                    <div className={formData.seccion === "home" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">
                                <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                                <input
                                    id= "background"
                                    name= "background"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    //{...formData.Date == 0 ? value === formData.background : value === ''}
                                    value = {formData.background}
                                />
                                <label htmlFor="body-text" className="panel-configuracion-item-title">Cambiar texto: </label>
                                <textarea 
                                        rows="12" 
                                        cols="48" 
                                        id="body-text"
                                        name="body-text"
                                        onChange={handleChange}
                                        value={formData.bodyText}>
                                </textarea>
                            </section>
                        </form>
                    </div>
                    
                    <div className={formData.seccion === "indicators" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">
                                <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                                <input
                                    id= "background"
                                    name= "background"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.background}
                                />
                                <label htmlFor="body-text" className="panel-configuracion-item-title">Cambiar texto según autonomía: </label>
                                <div className="panel-configuracion-item-content-radiogroup">

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="autonomia-fisica">Autonomía Física</label>
                                        <input 
                                            id='fisica'
                                            name='autonomy'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="'autonomia-economica'">Autonomía Economica</label>
                                        <input 
                                            id='economica'
                                            name='autonomy'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label>Autonomía Toma de decisiones</label>
                                        <input 
                                            id='decisiones'
                                            name='autonomy'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <textarea 
                                        rows="12" 
                                        cols="48" 
                                        id="body-text"
                                        name="body-text"
                                        onChange={handleChange}
                                        value={formData.bodyText}>
                                </textarea>
                            </section>
                        </form>
                    </div>

                    <div className={formData.seccion === "academy" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">
                                <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                                <input
                                    id= "background"
                                    name= "background"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.background}
                                />

                                <div className="panel-configuracion-item-title">
                                    <h4>Subir archivo: </h4>
                                </div>

                                <label htmlFor="titulo" className="panel-configuracion-item-title">Titulo: </label>
                                <input
                                    id= "titulo"
                                    name= "titulo"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.titulo}
                                />

                                <label htmlFor="instituto" className="panel-configuracion-item-title">Institución: </label>
                                <input
                                    id= "instituto"
                                    name= "instituto"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.instituto}
                                />

                                <div className="panel-configuracion-item-title">
                                    <h4>Autonomía: </h4>
                                </div>

                                <div className="panel-configuracion-item-content-radiogroup">

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="autonomia-fisica">Autonomía Física</label>
                                        <input 
                                            id='fisica'
                                            name='autonomy'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="'autonomia-economica'">Autonomía Economica</label>
                                        <input 
                                            id='economica'
                                            name='autonomy'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label>Autonomía Toma de decisiones</label>
                                        <input 
                                            id='decisiones'
                                            name='autonomy'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <label htmlFor="source" className="panel-configuracion-item-title">Fuente: </label>
                                <input
                                    id= "source"
                                    name= "source"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.source}
                                />

                            </section>
                        </form>
                    </div>

                    <div className={formData.seccion === "newsletters" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">
                                <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                                <input
                                    id= "background"
                                    name= "background"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.background}
                                />

                                <div className="panel-configuracion-item-title">
                                    <h4>Subir boletín: </h4>
                                </div>

                                <label htmlFor="titulo" className="panel-configuracion-item-title">Titulo: </label>
                                <input
                                    id= "titulo"
                                    name= "titulo"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.titulo}
                                />

                                <label htmlFor="portada" className="panel-configuracion-item-title">Portada: </label>
                                <input
                                    id= "portada"
                                    name= "portada"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.portada}
                                />

                                <label htmlFor="source" className="panel-configuracion-item-title">Fuente: </label>
                                <input
                                    id= "source"
                                    name= "source"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.source}
                                />

                            </section>
                        </form>
                    </div>

                    <div className={formData.seccion === "normativity" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">

                                <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                                <input
                                    id= "background"
                                    name= "background"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.background}
                                />

                                <div className="panel-configuracion-item-title">
                                    <h4>Agregar normas: </h4>
                                </div>

                                <label htmlFor="titulo" className="panel-configuracion-item-title">Titulo: </label>
                                <input
                                    id= "titulo"
                                    name= "titulo"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.titulo}
                                />

                                <label htmlFor="año" className="panel-configuracion-item-title">año: </label>
                                <input
                                    id= "año"
                                    name= "año"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.año}
                                />

                                <div className="panel-configuracion-item-title">
                                    <h4>Alcance: </h4>
                                </div>

                                <div className="panel-configuracion-item-content-radiogroup">

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="departamental">Departamental</label>
                                        <input 
                                            id='departamental'
                                            name='scope'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="nacional">Nacional</label>
                                        <input 
                                            id='nacional'
                                            name='scope'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="internacional">Internacional</label>
                                        <input 
                                            id='internacional'
                                            name='scope'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <label htmlFor="source" className="panel-configuracion-item-title">Fuente: </label>
                                <input
                                    id= "source"
                                    name= "source"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.source}
                                    required
                                />

                            </section>
                        </form>
                    </div>

                    <div className={formData.seccion === "news" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">

                            <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                            <input
                                id= "background"
                                name= "background"
                                type= "text"
                                placeholder="Link/URL"
                                onChange = {handleChange} 
                                value = {formData.background}
                            />

                            <div className="panel-configuracion-item-title">
                                <h4>Agregar noticias: </h4>
                            </div>

                            <label htmlFor="title" className="panel-configuracion-item-title">Titulo: </label>
                            <input
                                id= "title"
                                name= "title"
                                type= "text"
                                onChange = {handleChange} 
                                value = {formData.title}
                            />

                            <label htmlFor="transmitter" className="panel-configuracion-item-title">Emisor: </label>
                            <input
                                id= "transmitter"
                                name= "transmitter"
                                type= "text"
                                onChange = {handleChange} 
                                value = {formData.transmitter}
                            />

                            <label htmlFor="date" className="panel-configuracion-item-title">Fecha: </label>
                            <input
                                id= "date"
                                name= "date"
                                type= "date"
                                onChange = {handleChange} 
                                value = {formData.date}
                            />

                            <label htmlFor="transmitter" className="panel-configuracion-item-title">Fuente: </label>
                            <input
                                id= "source"
                                name= "source"
                                type= "text"
                                placeholder="Link/URL"
                                onChange = {handleChange} 
                                value = {formData.source}
                            />

                            </section>
                        </form>
                    </div>

                    <div className={formData.seccion === "politics" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">

                                <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                                <input
                                    id= "background"
                                    name= "background"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.background}
                                />

                                <div className="panel-configuracion-item-title">
                                    <h4>Agregar politicas: </h4>
                                </div>

                                <label htmlFor="title" className="panel-configuracion-item-title">Titulo: </label>
                                <input
                                    id= "title"
                                    name= "title"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.title}
                                />

                                <div className="panel-configuracion-item-content-radiogroup">
                                    <div className="panel-configuracion-item-title">
                                        <h4>Alcance: </h4>
                                    </div>
                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="departamental">Departamental</label>
                                        <input 
                                            id='departamental'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="pereira">Pereira</label>
                                        <input 
                                            id='pereira'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="apia">Apía</label>
                                        <input 
                                            id='apia'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="balboa">Balboa</label>
                                        <input 
                                            id='balboa'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="belen">Belén de Umbría</label>
                                        <input 
                                            id='belen'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="dosquebradas">Dosquebradas</label>
                                        <input 
                                            id='dosquebradas'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="guatica">Guática</label>
                                        <input 
                                            id='guatica'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="lacelia">La Celia</label>
                                        <input 
                                            id='lacelia'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="lavirginia">La Virginia</label>
                                        <input 
                                            id='lavirginia'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="marsella">Marsella</label>
                                        <input 
                                            id='marsella'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="mistrato">Mistrató</label>
                                        <input 
                                            id='mistrato'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="pueblorico">Pueblo Rico</label>
                                        <input 
                                            id='pueblorico'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="quinchia">Quinchía</label>
                                        <input 
                                            id='quinchia'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="santarosa">Santa Rosa</label>
                                        <input 
                                            id='santarosa'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="panel-configuracion-item-content-radiogroup-singleradio">
                                        <label htmlFor="santuario">Santuario</label>
                                        <input 
                                            id='santuario'
                                            name='town'
                                            type='radio'
                                            onChange={handleChange}
                                        />
                                    </div>

                                </div>

                                <label htmlFor="source" className="panel-configuracion-item-title">Fuente: </label>
                                <input
                                    id= "source"
                                    name= "source"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.source}
                                />

                            </section>
                        </form>
                    </div>

                    <div className={formData.seccion === "publications" ? "panel-configuracion-item active" : "panel-configuracion-item"}>
                        <form onSubmit={handleSubmit}>
                            <section className="panel-configuracion-item-content">

                                <label htmlFor="background" className="panel-configuracion-item-title">Cambiar fondo: </label>
                                <input
                                    id= "background"
                                    name= "background"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.background}
                                />

                                <div className="panel-configuracion-item-title">
                                    <h4>Agregar publicación: </h4>
                                </div>

                                <label htmlFor="title" className="panel-configuracion-item-title">Título: </label>
                                <input
                                    id= "title"
                                    name= "title"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.title}
                                />

                                <label htmlFor="entity" className="panel-configuracion-item-title">Entidad: </label>
                                <input
                                    id= "entity"
                                    name= "entity"
                                    type= "text"
                                    onChange = {handleChange} 
                                    value = {formData.title}
                                />

                                <label htmlFor="source" className="panel-configuracion-item-title">Fuente: </label>
                                <input
                                    id= "source"
                                    name= "source"
                                    type= "text"
                                    placeholder="Link/URL"
                                    onChange = {handleChange} 
                                    value = {formData.source}
                                />

                            </section>
                        </form>
                    </div>

                    { formData.updated === true &&  <div><br/><h4>Cambios guardados</h4></div>}

                    <div className="panel-configuracion-hubButtons">
                        <input id="upload-button" className="upload-button" value='Guardar' type="submit" onClick={handleSubmit}/>
                        <input className="clean-button" value='Limpiar' type="button" onClick={handleClearForm}/>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}

export default Configuracion;