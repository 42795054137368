import React, { Component } from "react";
import './BannerNormatividad.css';

class BannerNormatividad extends Component {
    render(){
        return (
            <div className="banner-normatividad">
                <h1>NORMATIVIDAD</h1>
            </div>
        );
    }
}


export default BannerNormatividad;