import React from "react";
import BannerProyectos from "../pageComponents/BannerProyectos";

const Proyectos = () => {
    return (
        <>
            <BannerProyectos />
        </>
    );
  };
  
export default Proyectos;