import {useState} from 'react';
//import { municipios }  from './LeftPanelElements'
import risOnMap from '../../images/map-ris-ris.png';
import peiOnMap from '../../images/map-ris-pei.png';
import apiOnMap from '../../images/map-ris-api.png';
import mapaApia from '../../images/maprisapi.png';
import './Dashboard.css';
import LinesChart from './PieCharts/LinesChart';
import LinesChart2 from './PieCharts/LinesChart2';
import BarsChart from './PieCharts/BarsChart';
import Pies from './PieCharts/PiesChart';
import BarsChartDesemp from './PieCharts/BarChartDesempleo';
import PieChartDesemp from './PieCharts/PieChartDesempleo';
import BarsChartOcupad from './PieCharts/BarChartOcupacion';
import PieChartOcupac from './PieCharts/PieChartOcupacion';
import BarsChartDelisexu from './PieCharts/BarChartDelisexuales';
import LinesChartDeliSexu from './PieCharts/LinesChartDelisexuales';
import PieChartFeminici from './PieCharts/PieChartFeminicidio';
import LinesChartFeminici from './PieCharts/LinesChartFeminicidio';


//import LeftPanel from './LeftPanel';

const Dashboard = () => {

    const municipios = [
        {
            nombre: 'Risaralda',
            poblacion: 943401
        },
        {
            nombre: 'Pereira',
            poblacion: 480000
        },
        {
            nombre: 'Apia',
            poblacion: 13000
        },
        {
            nombre: 'Balboa',
            poblacion: 6500
        },
        {
            nombre: 'Belén de Umbría',
            poblacion: 25000
        },
        {
            nombre: 'Dosquebradas',
            poblacion: 230000
        },
        {
            nombre: 'Guática',
            poblacion: 12000
        },
        {
            nombre: 'La Celia',
            poblacion: 7600
        },
        {
            nombre: 'La Virginia',
            poblacion: 8000
        },
        {
            nombre: 'Marsella',
            poblacion: 17208
        },
        {
            nombre: 'Mistrató',
            poblacion: 18000
        },
        {
            nombre: 'Pueblo Rico',
            poblacion: 12000
        },
        {
            nombre: 'Quinchía',
            poblacion: 28000
        },
        {
            nombre: 'Santa Rosa de Cabal',
            poblacion: 80000
        },
        {
            nombre: 'Santuario',
            poblacion: 13000
        },
    
    ];

    const[toggleState, setToggleState] = useState(1);
    const toggleMun = (index) => {
        //console.log(index);
        setToggleState(index);
    }

    return(
        <div className='panel'>
            <div className='left-panel'>
                <h3>Indicadores</h3>
                <div className ={toggleState === 2 ? 'panel-autonom selected' : 'panel-autonom'}
                    onClick={() => toggleMun(2)}>
                    <h4>Autonomía Toma de Decisiones</h4>
                </div>
                <div className ={toggleState === 1 ? 'panel-autonom selected' : 'panel-autonom'}
                    onClick={() => toggleMun(1)}>
                    <h4>Autonomía Física</h4>
                </div>
                <div className ={toggleState === 0 ? 'panel-autonom selected' : 'panel-autonom'}
                    onClick={() => toggleMun(0)}>
                    <h4>Autonomía Ecónomica</h4>
                </div>
                <div className ={toggleState === 3 ? 'panel-autonom selected' : 'panel-autonom'}
                    onClick={() => toggleMun(3)}>
                    <h4>Datos Demográficos</h4>
                </div>
            </div>

            <div className = {toggleState === 0 ? 'main-panel active': 'main-panel'}>

                <div className='main-panel-indicadores'>
                    <h3>Autonomía Económica</h3>
                    <br/>
                    <p>La autonomía económica se refiere a la capacidad de las mujeres de acceder y 
                        controlar recursos como los ingresos propios, activos, recursos productivos, 
                        financieros, tecnológicos y el tiempo. Considera el trabajo remunerado y no 
                        remunerado y el impacto de su distribución desigual entre hombres y mujeres.
                        Para conocer los datos de esta autonomía en el departamento, has click 
                        <a href='https://fm33.easytechpro.com/fmi/webd/Gobernacion%20Dashboard?homeurl=https://www.risaralda.gov.co/'> 
                            AQUÍ
                        </a>
                    </p>
                    <br/>
                    <p>
                        <em>fuente <a target='blank' href='https://oig.cepal.org/es/autonomias/autonomia-la-toma-decisiones'>CEPAL</a></em>
                    </p>

                    {/*<div className='main-panel-board'>
                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-desempleo'>
                                <div className='panel-indicador-title'>
                                    <h4>Tabla de desempleo</h4>
                                </div>
                                <div className='panel-indicador-data'>
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Año </h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2018</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2019</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2020</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2021</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2022</h5>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Total personas desempleadas</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>38.445</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>37.097</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>64.163</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>59.893</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>49.008</h5>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Mujeres</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>22.130</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>20.157</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>32.412</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>29.493</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>25.348</h5>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Hombres</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>16.315</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>16.940</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>31.751</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>30.400</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>23.660</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='panel-indicador-maindata'>

                                <div className='indicador-maindata-item'>
                                    <div className='maindata-item-title'>
                                        <h4>Tasa de desempleo mujeres</h4>
                                    </div>
                                    <div className='maindata-item-number'>
                                        <h4>13.50%</h4>
                                    </div>
                                </div>
                                <div className='indicador-maindata-item'>
                                    <div className='maindata-item-title'>
                                        <h4>Tasa de desempleo hombres</h4>
                                    </div>
                                    <div className='maindata-item-number'>
                                        <h4>8.66%</h4>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-bars'>
                                <BarsChartDesemp />
                            </div>
                            <div className='panel-indicador-pie'>
                                <PieChartDesemp />
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className='main-panel-board'>
                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-ocupacion'>
                                <div className='panel-indicador-title'>
                                    <h4>Tabla de ocupación</h4>
                                </div>
                                <div className='panel-indicador-data'>
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Año </h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2018</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2019</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2020</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2021</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>2022</h5>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Total personas ocupadas</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>444.165</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>434.789</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>376.766</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>381.091</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>411.814</h5>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Mujeres</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>183.340</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>178.841</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>147.724</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>145.057</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>163.372</h5>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h5>Hombres</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>261.861</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>256.948</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>229.042</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>236.034</h5>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h5>249.442</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='panel-indicador-maindata'>

                                <div className='indicador-maindata-item'>
                                    <div className='maindata-item-title'>
                                        <h4>Tasa de ocupación mujeres</h4>
                                    </div>
                                    <div className='maindata-item-number'>
                                        <h4>38.65%</h4>
                                    </div>
                                </div>
                                <div className='indicador-maindata-item'>
                                    <div className='maindata-item-title'>
                                        <h4>Tasa de ocupación hombres</h4>
                                    </div>
                                    <div className='maindata-item-number'>
                                        <h4>67.78%</h4>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-bars'>
                                <BarsChartOcupad />
                            </div>
                            <div className='panel-indicador-pie'>
                                <PieChartOcupac />
                            </div>
                        </div>
                    </div>*/}

                </div>
            </div>

            <div className = {toggleState === 1 ? 'main-panel active': 'main-panel'}>
                <div className='main-panel-indicadores'>
                    <h3>Autonomía Física</h3>
                    <br />
                    <p>La autonomía física se refiere a la capacidad de las mujeres, las adolescentes y las niñas en su 
                        diversidad, de vivir una vida libre de discriminación, prácticas nocivas y violencias y ejercer 
                        sus derechos sexuales y derechos reproductivos en condiciones adecuadas para ello.
                        Para conocer los datos de esta autonomía en el departamento, has click <a href='https://fm33.easytechpro.com/fmi/webd/Gobernacion%20Dashboard?homeurl=https://www.risaralda.gov.co/'> 
                        AQUÍ</a>
                    </p>
                    <br/>
                    <p>
                        <em>fuente <a target='blank' href='https://oig.cepal.org/es/autonomias/autonomia-la-toma-decisiones'>CEPAL</a></em>
                    </p>
                    {/*<div className='main-panel-board'>
                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-delisexuales'>
                                <div className='panel-indicador-title'>
                                    <h4>Tabla de delitos sexuales</h4>
                                </div>
                                <div className='panel-indicador-data'>
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Año </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2018</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2019</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2020</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2021</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2022</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Casos reportados </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>1113</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>998</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>863</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>824</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>294</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Casos hombres</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>162</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>128</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>151</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>117</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>45</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Casos mujeres </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>951</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>870</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>712</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>707</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>249</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>1era infancia(0-5 años)</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>160</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>140</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>95</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>85</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>42</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Infancia (6-11 años) </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>397</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>341</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>265</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>229</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>79</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Adolecencia (12-17 años)</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>442</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>384</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>342</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>366</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>116</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Juventud (18-28 años)</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>73</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>84</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>99</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>83</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>35</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Adultez (29-59 años) </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>36</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>46</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>59</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>58</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>22</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Mayor (60 años) </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>5</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>3</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>3</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>3</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>0</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Urbana </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>967</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>587</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>365</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>358</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>149</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Rural </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>146</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>411</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>498</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>466</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>145</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='panel-indicador-maindata'>

                            </div>
                        </div>
                        <div className='panel-indicador-table lines-delisexuales'>
                            <div className='panel-indicador-line'>
                                <LinesChartDeliSexu />
                            </div>
                            <div className='panel-indicador-maindata delisexuales'>
                                <div className='indicador-maindata-item'>
                                    <div className='maindata-item-title'>
                                        <h4>Casos reportados</h4>
                                    </div>
                                    <div className='maindata-item-number'>
                                        <h4>294</h4>
                                    </div>
                                </div>
                                <div className='indicador-maindata-item'>
                                    <div className='maindata-item-title'>
                                        <h4>Casos hombres</h4>
                                    </div>
                                    <div className='maindata-item-number'>
                                        <h4>45</h4>
                                    </div>
                                </div>
                                <div className='indicador-maindata-item'>
                                    <div className='maindata-item-title'>
                                        <h4>Casos mujeres</h4>
                                    </div>
                                    <div className='maindata-item-number'>
                                        <h4>249</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-bars'>
                                <BarsChartDelisexu />
                            </div>

                        </div>
                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-feminicidio'>
                                <div className='panel-indicador-title'>
                                    <h4>Tabla de feminicidio</h4>
                                </div>
                                <div className='panel-indicador-data'>
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Año </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2018</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2019</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2020</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2021</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2022</h6>
                                        </div>
                                    </div>
                                
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Total casos</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>6</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>7</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>1</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>9</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2</h6>
                                        </div>
                                    </div>
                                
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>1era infancia (0-5 años)</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                    </div>
                                
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Infancia (6-11 años)</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                    </div>
                                
                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Adolecencia (12-17 años)</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>1</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>1</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Juventud (18-28 años)</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>2</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>3</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>5</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>1</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Adultez (29-59 años) </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>4</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>3</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>4</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Mayor (60 años) </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Urbana </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>6</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>7</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>6</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>1</h6>
                                        </div>
                                    </div>

                                    <div className='panel-indicador-group'>
                                        <div className='panel-indicador-title'>
                                            <h6>Rural </h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>N/R</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>3</h6>
                                        </div>
                                        <div className='panel-indicador-data'>
                                            <h6>1</h6>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='panel-indicador-table'>
                            <div className='panel-indicador-pie'>
                                <PieChartFeminici />
                            </div>
                            <div className='panel-indicador-line'>
                                <LinesChartFeminici />
                            </div>
                        </div>
                    </div>*/}
                </div>


                {/*<div className='main-panel-charts'>
                    <div className='main-panel-charts-map'>
                        <img src={peiOnMap} alt='mapa-pereira' />
                    </div>
                    <div className='main-panel-charts-graphs'>
                        <div className='main-panel-charts-graphs-pie'>
                            {/*<PieChartpei />*/}
                        {/*</div>
                        <div className='main-panel-charts-graphs-pie'>
                            {/*<PieChart2pei />
                            <LinesChart2 />/*/}
                        {/*</div>
                    </div>
                </div>*/}
                <br />

                {/*<div className='main-panel-autonomias'>
                    
                    <div className='panel-aut'>
                        <div className='panel-aut-title-icon'>
                            <h4>Autonomía <br/> Física</h4>
                            <i class="fa-solid fa-user-nurse"></i>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Delitos <br /> Sexuales</h5>
                            <h5>138</h5>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Casos mujeres</h5>
                            <h5>119</h5>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Casos hombres</h5>
                            <h5>19</h5>
                        </div>
                    </div>

                    <div className='panel-aut'>
                        <div className='panel-aut-title-icon'>
                            <h4>Autonomía Toma de Decisiones</h4>
                            <i class="fa-solid fa-audio-description"></i>
                        </div>
                        <br />
                        <h5>Gobernación:</h5>
                        <div className='panel-aut-content'>
                            <h5>mujeres inscritas</h5>
                            <h5>N/A</h5>
                        </div>
                        <br />
                        <h5>Alcaldía:</h5>
                        <div className='panel-aut-content'>
                            <h5>mujeres inscritas</h5>
                            <h5>2</h5>
                        </div>
                        <br />
                        <h5>Consejo:</h5>
                        <div className='panel-aut-content'>
                            <h5>mujeres inscritas</h5>
                            <h5>71</h5>
                        </div>
                    </div>

                    <div className='panel-aut'>
                        <div className='panel-aut-title-icon'>
                            <h4>Autonomía <br/> Económica</h4>
                            <i class="fa-solid fa-money-bill"></i>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Personas <br /> desempleadas</h5>
                            <h5>N/R</h5>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Mujeres</h5>
                            <h5>N/R</h5>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Hombres</h5>
                            <h5>N/R</h5>
                        </div>
                    </div>

                    <div className='panel-aut'>
                        <div className='panel-aut-title-icon'>
                            <h4>Autonomía Belem Dopara</h4>
                            <i class="fa-solid fa-venus"></i>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Atención a <br /> la violencia</h5>
                            <h5>N/A</h5>
                        </div>
                        <br />
                        <div className='panel-aut-content'>
                            <h5>Porcentaje de <br/> mujeres que <br /> sufren violencia</h5>
                            <h5>N/A</h5>
                        </div>
                    </div>
                </div>*/}
            </div>

            <div className = {toggleState === 2 ? 'main-panel active': 'main-panel'}>
                <div className='main-panel-indicadores'>
                    <h3>Autonomía Toma de Decisiones</h3>
                    <br />
                    <p>La autonomía en la toma de decisiones se refiere a la plena participación de las mujeres en 
                        igualdad de condiciones en los distintos ámbitos de la vida pública y política.
                        Para conocer los datos de esta autonomía en el departamento, has click <a href='https://fm33.easytechpro.com/fmi/webd/Gobernacion%20Dashboard?homeurl=https://www.risaralda.gov.co/'> 
                        AQUÍ</a>
                    </p>
                    <br/>
                    <p>
                        <em>fuente <a target='blank' href='https://oig.cepal.org/es/autonomias/autonomia-la-toma-decisiones'>CEPAL</a></em>
                    </p>

                </div>
            </div>

            <div className = {toggleState === 3 ? 'main-panel active': 'main-panel'}>
            <h3>Datos demográficos más relevantes:</h3>
                <div className='main-panel-charts'>
                    <div className='main-panel-charts-data'>

                        <div className='main-panel-charts-item'>
                            <div className='main-panel-charts-item-title'>
                                <h4>Cantidad de habitantes</h4>
                            </div>
                            <div className='main-panel-charts-item-number'>
                                <h4>904.401</h4>
                            </div>
                        </div>
                        <div className='main-panel-charts-item'>
                            <div className='main-panel-charts-item-title'>
                                <h4>Cantidad de mujeres</h4>
                            </div>
                            <div className='main-panel-charts-item-number'>
                                <h4>454.913</h4>
                            </div>
                        </div>
                        <div className='main-panel-charts-item'>
                            <div className='main-panel-charts-item-title'>
                                <h4>Cantidad de hombres</h4>
                            </div>
                            <div className='main-panel-charts-item-number'>
                                <h4>449.487</h4>
                            </div>
                        </div>

                    </div>

                    <div className='main-panel-charts-map'>
                        <img src={risOnMap} alt='mapa-risaralda' />
                    </div>
                    
                </div>
                <br />
            </div>
        </div>
    );
}

export default Dashboard