import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

export function Button (){
    return(
        <Link to="contactenos">
            <button className="btn"> Contáctenos</button>
        </Link>
    )
}