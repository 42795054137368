import React, { useState } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Dropdown from "./Dropdown"
import logoNavbar from "../images/LOGOnavbar.png";
import logo2Navbar from "../images/LOGO OBSERVATORIO.png";

function Navbar(){
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click); 
    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () =>{
        if(window.innerWidth < 960){
            setDropdown(false);
        }else{
            setDropdown(true);
        }
    }

    const onMouseLeave = () =>{
        if(window.innerWidth < 960){
            setDropdown(false);
        }else{
            setDropdown(false);
        }
    }

    return(
        <>
            <nav className="navbar">
                <Link to='/' className="navbar-logo">
                    <img className="logo-navbar" src={logo2Navbar} alt="logo-observatorio"/>
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? "fas fa-times" : "fas fa-bars"} />
                </div>
                <ul className={ click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link to="/indicadores/#Boletines" className="nav-links" onClick={ closeMobileMenu }>
                            Indicadores
                        </Link>
                    </li>
                    <li className="nav-item"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <Link to="/objetivo" className="nav-links" onClick={ closeMobileMenu }>
                            Información <i className="fas fa-caret-down" />
                        </Link>
                        {dropdown && <Dropdown/> }
                    </li>
                    <li className="nav-item">
                        <Link to="/eventos" className="nav-links" onClick={ closeMobileMenu }>
                            Noticias
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contactenos" className="nav-links-mobile" onClick={ closeMobileMenu }>
                            Contáctenos
                        </Link>
                    </li>
                </ul>
                <Button />
            </nav>
        </>
    );
}

export default Navbar;