import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register (
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

var beneficios = [30, 56, 40, 26, 60, 30, 30, -10, 25, 30, 12, 58];
var rendimiento = [20, 66, 50, 36, 70, 40, 40, -15, 35, 20, 22, 38];
var meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']

var midata = {
    labels: meses,
    datasets: [//Cada una de las lineas del grafico
        {
            label: 'Beneficios',
            data: beneficios,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Rendimiento',
            data: rendimiento,
            backgroundColor: 'rgba(0, 220, 195, 0.5)',
        },
        

    ]

}

var misoptions = {
    response: true,
    animation: false,
    plugins: {
        legend: {
            display: true
        }
    },
    scales: {
        y: {
            min: -25,
            max: 100
        },
        x: {
            ticks: {color: 'gray'}
        }
    }
}

export default function BarsChart() {
    return <Bar data={midata} options={misoptions} />
}