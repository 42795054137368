import React from "react";
import BannerNormatividad from "../pageComponents/BannerNormatividad";
import BodyNormatividad from "../pageComponents/BodyNormatividad";

const Normatividad = () => {
    return (
        <>
            <BannerNormatividad />
            <BodyNormatividad />
        </>
    )
  };
  
export default Normatividad;