import React, { Component } from "react";
import './BannerPublicaciones.css';

class BannerPublicaciones extends Component {
    render() { 
        return(
            <div className="banner-publicaciones">
                <h1> PUBLICACIONES </h1>
            </div>
        );
    }
}

export default BannerPublicaciones;