import React, { useState } from "react";
import './BodyEventos.css';

const BodyEventos = () => {
    const[toggleState, setToggleState] = useState(0);

    const toggleEntity = (index) => {
        setToggleState(index);
    }
    
    return(
        <div className="body-eventos">
            <div className="body-eventos-text">

                <h2> ACTUALIDAD </h2>
                <br/>
                <p>Consulte según su interés haciendo click</p>
                <br />
            </div>

            <div className="body-eventos-cards">

                <div className = {toggleState=== 0 ? "eventos-card au-fisica active" : "eventos-card au-fisica"}
                     onClick={() => toggleEntity(0)}>
                    <h3 className="eventos-card-title">Departamentales</h3>
                </div>

                <div className = {toggleState=== 1 ? "eventos-card au-economica active" : "eventos-card au-economica"}
                     onClick={() => toggleEntity(1)}>
                    <h3 className="eventos-card-title">Nacionales</h3>
                </div>

                <div className = {toggleState=== 2 ? "eventos-card au-decisiones active" : "eventos-card au-decisiones"}
                     onClick={() => toggleEntity(2)}>
                    <h3 className="eventos-card-title">Internacionales</h3>
                </div>

            </div>
            <div className="body-eventos-noticias">
                
                <div className="noticias-bar">
                    <div className="noticias-title fuente">
                        <h3>Fuente</h3>
                    </div>
                    <div className="noticias-title titulo">
                        <h3>Título</h3>
                    </div>
                    <div className="noticias-title">
                        <h3>Fecha</h3>
                    </div>
                    <div className="noticias-title">
                        <h3>Enlace</h3>
                    </div>
                </div>

                <div className={toggleState=== 0 ? "noticias-departamentales active" : "noticias-departamentales"}>
                    
                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            <h4>El blog del ministro: Portal de noticias del periodista Octavio Cardona Gonzales</h4>
                        </div>
                        <div className="noticias-row-titulo">
                        <h4><em>Experiencias que inspiran para continuar trabajando por las mujeres risaraldenses
                            </em></h4>
                        </div>
                        <div className="noticias-row-fecha">
                            <h4>Noviembre 28, 2023</h4>
                        </div>
                        <div className="noticias-row-enlace">
                            <a target="blank" href="https://www.elblogdelministro.com/2023/11/experiencias-que-inspiran-seguir.html?m=1"><h4>blogdelministro.com</h4></a>
                        </div>
                    </div>

                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            <h4>El blog del ministro: Portal de noticias del periodista Octavio Cardona Gonzales</h4>
                        </div>
                        <div className="noticias-row-titulo">
                        <h4><em>Así conmemoró la Gobernación el Día Internacional para la Eliminación de la 
                                Violencia contra la Mujer
                            </em></h4>
                        </div>
                        <div className="noticias-row-fecha">
                            <h4>Noviembre 23, 2023</h4>
                        </div>
                        <div className="noticias-row-enlace">
                            <a target="blank" href="https://www.elblogdelministro.com/2023/11/asi-conmemoro-la-gobernacion-el-dia.html?m=1"><h4>blogdelministro.com</h4></a>
                        </div>
                    </div>

                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            <h4>Risaralda TV: Medio de comunicación de noticias en plataforma de Facebook</h4>
                        </div>
                        <div className="noticias-row-titulo">
                        <h4><em>Desde la Gobernación de Risaralda con la entrega de más de $350 millones en incentivos, 
                            conmemoran el Día Internacional para la Eliminación de la Violencia contra la Mujer
                            </em></h4>
                        </div>
                        <div className="noticias-row-fecha">
                            <h4>Noviembre 22, 2023</h4>
                        </div>
                        <div className="noticias-row-enlace">
                            <a target="blank" href="https://www.facebook.com/100024879196205/posts/1361610744678219/?mibextid=Na33Lf"><h4>facebook.com</h4></a>
                        </div>
                    </div>

                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            <h4>Area Política: Medio de comunicación digital</h4>
                        </div>
                        <div className="noticias-row-titulo">
                        <h4><em>Gobernación crea el Observatorio de Asuntos de Género de Risaralda
                            </em></h4>
                        </div>
                        <div className="noticias-row-fecha">
                            <h4>Octubre 25, 2023</h4>
                        </div>
                        <div className="noticias-row-enlace">
                            <a target="blank" href="https://areapolitica.com/gobernacion-crea-el-observatorio-de-asuntos-de-genero-de-risaralda/"><h4>areapolitica.com</h4></a>
                        </div>
                    </div>

                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            <h4>Perfil oficial de Facebook Alcaldía de La Celia</h4>
                        </div>
                        <div className="noticias-row-titulo">
                        <h4><em>En sesión del Consejo Comunitario de Mujeres de La Celia, 
                            se valida el documento técnico de la Política Pública de Equidad de Género para las mujeres
                            </em></h4>
                        </div>
                        <div className="noticias-row-fecha">
                            <h4>Octubre 24, 2023</h4>
                        </div>
                        <div className="noticias-row-enlace">
                            <a target="blank" href="https://www.facebook.com/story.php?story_fbid=777160307755245&id=100063838474743&mibextid=hubsqH"><h4>facebook.com</h4></a>
                        </div>
                    </div>

                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            <h4>Risaralda 100.2 Tu Radio: Emisora oficial de la Gobernación de Risaralda</h4>
                        </div>
                        <div className="noticias-row-titulo">
                        <h4><em>Espacio radial RISARALDA CON VOZ DE MUJER
                            </em></h4>
                        </div>
                        <div className="noticias-row-fecha">
                            <h4>Programas entre Noviembre y Diciembre de 2023</h4>
                        </div>
                        <div className="noticias-row-enlace">
                            <a target="blank" href="https://www.facebook.com/story.php?story_fbid=777160307755245&id=100063838474743&mibextid=hubsqH"><h4>facebook.com</h4></a>
                        </div>
                    </div>
                    
                </div>

                <div className={toggleState=== 1 ? "noticias-nacionales active" : "noticias-nacionales"}>
                    
                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            <h4>MASCOLOMBIA: Portal de noticias</h4>
                        </div>
                        <div className="noticias-row-titulo">
                        <h4><em>Así es el incentivo violeta, un beneficio tributario con el que empresas 
                                pueden deducir hasta 200% de su impuesto de renta
                            </em></h4>
                        </div>
                        <div className="noticias-row-fecha">
                            <h4>Diciembre 5, 2023</h4>
                        </div>
                        <div className="noticias-row-enlace">
                            <a target="blank" href="https://mascolombia.com/incentivo-violeta-un-beneficio-tributario-para-empresas/"><h4>mascolombia.com</h4></a>
                        </div>
                    </div>

                </div>

                <div className={toggleState=== 2 ? "noticias-internacionales active" : "noticias-internacionales"}>
                    <div className="noticias-row">
                        <div className="noticias-row-fuente">
                            Información no disponible
                        </div>
                        <div className="noticias-row-titulo">
                            Información no disponible
                        </div>
                        <div className="noticias-row-fecha">
                            Información no disponible
                        </div>
                        <div className="noticias-row-enlace">
                            Información no disponible
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BodyEventos