import React from "react";
import BannerPoliticas from "../pageComponents/BannerPoliticas";
import BodyPoliticas from "../pageComponents/BodyPoliticas";

const Politicas = () => {
    return (
        <>
            <BannerPoliticas />
            <BodyPoliticas />
        </>
    )
};
  
export default Politicas;