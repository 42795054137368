import './BodyBoletines.css';
import React from 'react';
import LogoObs from "../../images/LOGO OBSERVATORIO.png";
import ImgForo from "../../images/fotoBoletin1-2.jpeg";
import Portada1 from "../../images/Portada1.png";
import Portada2 from "../../images/Portada2.png";

const BodyBoletines = () => {
    return(
        <div className='body-boletines'>
            {/*<div className='pagina-portada'>

                <div className='portada-encabezado'>

                    <div className='portada-encabezado-imagen'>
                        <p><em>fuente: Pexels</em></p>
                    </div>

                    <div className='portada-encabezado-titulo'>
                        <div className='encabezado-titulo-principal'>
                            <h2>
                                BALANCE POSITIVO DE LAS INVESTIGACIONES DE ASUNTOS DE GÉNERO EN RISARALDA 
                            </h2>

                            <h4>
                                Cráter Living Lab, el primer foro para reflexionar sobre cómo va la investigación 
                                en asuntos de género en el departamento.
                            </h4>
                        </div>
                        <div className='encabezado-titulo-datosboletin'>
                            <h3>
                                Boletín No 1
                            </h3>
                            <h5>
                                Octubre de 2023
                            </h5>
                        </div>
                    </div>
                </div>

                <div className='portada-intro'>
                    <div className='portada-intro-leftpanel'>
                        <div className='intro-leftpanel-text'>
                            <h4> - Introdicción </h4>
                            <h4> - Desarrollo del evento </h4>
                            <h4> - Conclusiones </h4>
                        </div>
                        <div className='intro-leftpanel-pics'>
                            <img src={LogoObs} alt="logo-oas-risaralda" />
                        </div>
                    </div>

                    <div className='portada-intro-maincontent'>
                        <div className='intro-maincontent-text'>
                            <h6>
                                Con presencia de casi un centenar de personas pertenecientes a entidades gubernamentales, 
                                organizaciones de mujeres, delegados de las más importantes universidades del departamento 
                                de Risaralda y con la participación de estudiantes de la Fundación Universitaria del 
                                Área Andina, se desarrolló en el Cráter Living Lab, el primer foro para reflexionar 
                                sobre cómo va la investigación en asuntos de género en el departamento.
                            </h6>
                            <br/>
                            <h6>
                                El evento convocado por la Secretaria de mujer, familia y desarrollo social; 
                                desarrolló una agenda académica en la que se presentó el estado de avance del primer 
                                Observatorio de Asuntos de Género  que  tendrá el departamento de Risaralda y que obedece 
                                a una de las metas incluídas en el Plan de desarrollo SENTIMIENTO DE TODOS del 
                                Gobernador Victor Manuel Tamayo Vargas, para cumplir con una de las acciones afirmativas 
                                de la Política Pública de equidad de género para las mujeres de Risaralda (Ordenanza 008 de 2023)
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='pagina-contenido'>
                <div className='contenido-encabezado'>
                    <div className='contenido-encabezado-item'>
                        <h5>Boletín OAS Risaralda</h5>
                    </div>
                    <div className='contenido-encabezado-item'>
                        <h5>Octubre de 2023</h5>
                    </div>
                    
                </div>
                <hr/>
                <div className='contenido-texto'>
                    <div className='contenido-texto-parrafo'>
                        <p>
                        También se expusieron las apuestas que en materia de investigación en género están adelantando 
                        la Universidad Tecnológica de Pereira, la Universidad Católica y la  
                        Fundación Universitaria del Área Andina.
                        </p>
                    </div>
                    <br/>
                    <div className='contenido-texto-parrafo'>
                        <p>
                        El acto central consistió en un conversatorio con las  investigadoras Bárbara de las Mercedes Zamora 
                        (Obstetra de la Universidad de Concepción de Chile, Magister en Educación problémica,  
                        Profesora e investigadora del programa de enfermería de la FUAA, Asesora de proyectos y evaluadora 
                        en Revistas Científicas, Par académica del Ministerio de Educación, Ponente en eventos científicos 
                        en temas de salud y educación), Sonia Valencia Yepes (Médica de la UTP Y Magíster en Educación con 
                        Énfasis en Género y Educación Popular de Univalle. Vinculada a la UTP como Docente catedrática de las 
                        facultades de educación y salud, Líder del centro de estudios de género y del semillero Perspectiva de género 
                        y educación. Actualmente es coordinadora de la red universitaria por la equidad de género Risaralda) 
                        Y Johanna García Ruiz (Comunicadora Social -Periodista, Magíster en Estudios culturales, investigadora 
                        y realizadora audiovisual. Vinculada a la Universidad Católica de Pereira como  Directora del programa de 
                        Comunicación Social, Docente e Investigadora del grupo Comunicación, educación  y cultura)
                        </p>
                    </div>
                    <br/>
                    <div className='contenido-texto-img'>
                        <img src={ImgForo} />
                    </div>
                    <br/>
                    <div className='contenido-texto-parrafo'>
                        <p>
                        En el conversatorio moderado por la coordinadora del OAG,  Claudia Lorena Betancourt Cano, las investigadoras 
                        contaron sobre lo que las motivó a hacer investigaciones en género, cómo han vivido esta experiencia y cuáles 
                        han sido los aprendizajes más importantes tanto del proceso como de las investigaciones  adelantadas. 
                        </p>
                    </div>
                    <br/>
                    <div className='contenido-texto-parrafo'>
                        <p>
                            Dentro de las conclusiones más importantes de este ejercicio se destaca que:
                        </p>
                        <br/>
                        <ul>
                            <li>
                                <p> Las mujeres que investigan temas de género, han atravesado inicialmente por un 
                                    cuestionamiento y una experiencia personal en las que han visto en su propia vida, 
                                    cómo se presentan la desigualdad y la discriminación hacia las mujeres. 
                                </p>
                            </li>
                            <br/>
                            <li>
                                <p>
                                    Para desarrollar sus investigaciones, tuvieron que cumplir con las exigencias propias 
                                    de cada universidad a fin de poder contar con los recursos necesarios para adelantar 
                                    sus estudios. Los estudios de género han ido tomando importancia en los últimos años 
                                    y se entiende más su relevancia en diferentes campos del conocimiento.
                                </p>
                            </li>
                            <br/>
                            <li>
                                <p>
                                    Lo más valioso de las investigaciones de cada ponente, ha sido la forma en que pueden 
                                    relacionarse con otras mujeres y ser empáticas, contribuyendo con sus estudios a 
                                    comprender mejor las situaciones  que afrontan las víctimas o mujeres discriminadas desde
                                     la óptica de la disciplina que representa cada una.
                                </p>
                            </li>
                            <br/>
                        </ul>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='pagina-contenido'>
                <div className='contenido-encabezado'>
                    <div className='contenido-encabezado-item'>
                        <h5>Cráter Living Lab, el primer foro</h5>
                    </div>
                    <div className='contenido-encabezado-item'>
                        <h5>Octubre de 2023</h5>
                    </div>
                    
                </div>
                <hr/>
                <div className='contenido-texto'>
                    <div className="contenido-texto-parrafo">
                        <ul>
                            <li>
                                <p>
                                    Para que se avance en el cierre de brechas de género y para que la sociedad en general comprenda que 
                                    los asuntos de género son asuntos del desarrollo y no un tema poco relevante, se tiene que seguir haciendo 
                                    investigación y difundiendo ampliamente los resultados de los estudios.   
                                </p>
                            </li>    
                        </ul>       
                    </div>
                    <br/>
                    <div className="contenido-texto-parrafo">
                        <p>
                            Nota: Estos estudios estarán referenciados y compilados en la página web del OAG para que sean 
                            consultados por las personas interesadas en ampliar conocimientos y formular nuevos proyectos.
                        </p>
                    </div>
                </div>
                        </div>*/}
            
            <h2> Encuentra todos los boletines emitidos por el Observatorio de Asuntos de Género de Risaralda</h2>
            
            <div className='boletines-container'>

                <div className='boletin-card'>
                    <a className='boletin-card' href='https://drive.google.com/file/d/1bkHccyR4kNRcRpgsq7YlLPhwzbCpX-dV/view'>
                        <div className='boletin-pic'>
                            <img src={Portada2} alt='Boletin 2'/>
                        </div>
                        <div className='boletin-body'>
                            <h4>Balance Positivo Sobre Las Investigaciones de Asuntos de Género En Risaralda</h4>
                            <h5>Boletín No 2</h5>
                            <h6>Octubre 2023</h6>
                        </div>
                    </a>
                </div>
            
                <div className='boletin-card'>
                    <a className='boletin-card' href='https://drive.google.com/file/d/1SVHDA3wsfUNGZSMoYPzBy4U8RJfftevv/view'>
                        <div className='boletin-pic'>
                            <img src={Portada1} alt='Boletin 1'/>
                        </div>
                        <div className='boletin-body'>
                            <h4>Avances En La Consolidación Del Observatorio de Asuntos de Género Risaralda</h4>
                            <h5>Boletín No 1</h5>
                            <h6>Septiembre 2023</h6>
                        </div>
                    </a>
                </div>

            </div>
            
                        

        </div>
    );
 }

export default BodyBoletines;