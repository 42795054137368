import React from "react";
import { Link } from "react-router-dom";
import "./../pageComponents/Contactenos.css";

const Contactenos = () => {
    return( 
        <div className="contactenos">
            <h1 className="contacto-title">Contáctenos</h1>
            <div className="contacto-text-item">
                <h5>Tel: 606 3398300 ext 319</h5>
                <i class="fa-solid fa-phone"></i>
            </div>
            <div className="contacto-text-item">
                <h5>observatoriorisaraldamujer@gmail.com</h5>
                <i class="fa-solid fa-envelope"></i>
            </div>
            <div className="contacto-text-item">
                <h5>mujeryasuntosdegenero@risaralda.gov.co</h5>
                <i class="fa-solid fa-envelope"></i>
            </div>
            <div className="contacto-text-item">
                <h5>Calle 19 No. 13-17 Parque Olaya Herrera - Pereira</h5>
                <i class="fa-solid fa-location-dot"></i>
            </div>
            <div className="contacto-text-item">
                <Link to="/acceso" className="">
                    <h5>Acceso administrador</h5>
                </Link>
            </div>
        </div>
        )
  };
  
export default Contactenos;