import React, { Component } from "react";
import './BodyIndicadores.css';
import Dashboard from "../dashboardComponents/Dashboard";

class BodyIndicadores extends Component {
    render(){
        return(
            <div className="body-indicadores">
                <div className="body-indicadores-text">
                    <h2>Autonomías</h2>
                    <br/>
                    <p>Para conocer el informe de manera más detallada, ingresar <spam><a href="https://fm33.easytechpro.com/fmi/webd/Gobernacion%20Dashboard?homeurl=https://www.risaralda.gov.co/">AQUÍ</a></spam></p>
                </div>
                <br/>
                <div className="indicadores-dashboard">
                    <Dashboard />
                </div>
            </div>
            
        );
    }
}

export default BodyIndicadores