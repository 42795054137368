import React, { Component } from "react";
import LogoObs from "../../images/LOGO OBSERVATORIO.png";
import './BannerHome.css';

class BannerHome extends Component {
    render() { 
        return(
            <div className="banner-home">
                {/*<h1> OBSERVATORIO DE ASUNTOS DE GÉNERO </h1>
                <h2>Departamento de Risaralda</h2>*/}
                <div className="banner-home-container-img">
                    <img src={LogoObs} alt="logo-observatorio-de-generos-risaralda"/>
                </div>
            </div>
        );
    }
}

export default BannerHome;