import React, { Component } from "react";
import './BannerPoliticas.css';

class BannerPoliticas extends Component {
    render() { 
        return(
            <div className="banner-politicas">
                <h1> POLÍTICAS PÚBLICAS </h1>
            </div>
        );
    }
}

export default BannerPoliticas;