import React, { Component } from "react";
import './BannerIndicadores.css';

class BannerIndicadores extends Component {
    render(){
        return(
            <div className="banner-indicadores">
                <h1>INDICADORES</h1>
            </div>
        );
    }
}

export default BannerIndicadores;