export const MenuItems = [
    {
        title: 'Academia',
        path: '/academia',
        cName: 'dropdown-link'
    },
    {
        title: 'Boletines',
        path: '/boletines',
        cName: 'dropdown-link'
    },
    {
        title: 'Normatividad',
        path: '/normatividad',
        cName: 'dropdown-link'
    },
    {
        title: 'Noticias/Eventos',
        path: '/eventos',
        cName: 'dropdown-link'
    },
    {
        title: 'Política Pública',
        path: '/politicas',
        cName: 'dropdown-link'
    },
    {
        title: 'Proyectos',
        path: '/proyectos',
        cName: 'dropdown-link'
    },
    {
        title: 'Publicaciones',
        path: '/publicaciones',
        cName: 'dropdown-link'
    }
]