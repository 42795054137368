import React from "react";
import BannerHome from "../pageComponents/BannerHome";
import BodyHome from "../pageComponents/BodyHome";

const Home = () => {
    return (
        <>
            <BannerHome />
            <BodyHome />
        </>
    )
  };
  
export default Home;